import React,{useState,useEffect} from 'react'
import withReceptionLayout from '../../withReceptionLayout'
import receptionCheckinServices from '../receptionCheckinServices';
import { useAlert } from '../../../../context/AlertContext';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import { Step, StepContent, StepLabel, Stepper } from '@mui/material';
import PatientSearchComponent from '../../../patient/components/PatientSearchComponent';
import { useNavigate } from 'react-router-dom';


function ReceptionScheduleCheckinPage() {

    const {AppAlert} = useAlert();
    const [queueList,setQueueList]  = useState(null);
    const [selectedQueue,setSelectedQueue] = useState(null);
    const [selectedDate,setSelectedDate] = useState(null);
    const [selectedService,setSelectedService]  = useState(null);
    const [activeStep,setActiveStep] = useState(0);
    const [availableSlots,setAvailableSlot] = useState(null);
    const [queueServiceList,setQueueServiceList] = useState(null);

    const [patientDetails,setPatientDetails] = useState(null);
    const [refresh,setRefresh] = useState(null);
    
    const getQueueList = () =>{
        receptionCheckinServices.getScheduleQueueList().then(res=>{
            setQueueList(res.data);
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }

    useEffect(()=>{
        getQueueList();
    },[])

    const selectQueue = (queue) => {
        setSelectedQueue(queue);
        setAvailableSlot(null);
        setActiveStep(1);
        setQueueServiceList(null);
    }

    const selectService = (service) =>{
        setSelectedService(service);
        setActiveStep(2);
    }

    
    
    const getAvailableSlots = () =>{
        if(selectedQueue !== null){
            setAvailableSlot(null);
            setQueueServiceList(null);
            let payload = {
                date: moment(selectedDate).format("YYYY-MM-DD"),
                queueId:selectedQueue.id,
            }
            receptionCheckinServices.getAvailableSlots(payload).then(res=>{
                setAvailableSlot(res.data.count);
                setQueueServiceList(res.data.queueServiceList);
            }).catch(error=>{
                AppAlert(error.msg,"error")
            })
        }
    }

    useEffect(()=>{
        getAvailableSlots();
    },[selectedDate])

    const navigate = useNavigate();
    const doScheduleCheckin = () =>{
        let payload = {
            queueId:selectedQueue.id,
            serviceId:selectedService.id,
            patientId:patientDetails.id,
            selectedDate:moment(selectedDate).format("YYYY-MM-DD"),
        }
        console.log(payload);
        receptionCheckinServices.doScheduleCheckin(payload).then(res=>{
            AppAlert(res.msg,"info");
            if(res.tokenPaymentTime == 1){
                //socket.emit("send_new_token",{message:"new token received"})
            }
            navigate("/reception/token-payment/"+patientDetails.id)
        }).catch(error=>{

        })
    }

    return (
        <div className='card'>
            <div className='card-body'>

                <Stepper activeStep={activeStep} orientation="vertical" >
                    <Step>
                        <StepLabel>{selectedQueue == null ? "Select Queue" : selectedQueue.title}</StepLabel>
                        <StepContent>
                            <div className='list-group rounded-0'>
                                {queueList && queueList.map(queue=>
                                    <button type="button" className="list-group-item" onClick={()=>selectQueue(queue)} > {queue.title}</button>
                                )}
                            </div>                                
                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel>{selectedService == null ? "Select Date & Service" : "For "+selectedService.title+" on "+moment(selectedDate).format("LL") }  </StepLabel>
                        <StepContent>
                            {selectedQueue &&
                                <div className='row mt-3'>
                                    <div className='col-md-6'>
                                        <Calendar 
                                            onChange={setSelectedDate} 
                                            value={selectedDate}
                                            minDate={moment().add(1,"day").toDate()} 
                                            maxDate={moment().add(selectedQueue.maxFutureDays,"day").toDate()}   
                                        />
                                    </div>
                                    {availableSlots && queueServiceList &&
                                        <div className='col-md-6'>
                                                <div className='list-group rounded-0'>
                                                    <span className="list-group-item bg-primary text-white text-center">{availableSlots} Slots Available</span>
                                                    {queueServiceList && queueServiceList.map(service=>
                                                        <button type="button" className="list-group-item" onClick={()=>selectService(service)} >{service.title}</button>
                                                    )}
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        
                                                    </div>
                                                </div> 
                                        </div>
                                    }
                                </div>
                            }
                            <button className='btn btn-outline-primary mt-3' onClick={()=>setActiveStep(0)}>Back</button>
                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel>Patient Details </StepLabel>
                        <StepContent>
                            {selectedQueue && selectedService &&
                                <PatientSearchComponent
                                    patientDetails={patientDetails} setPatientDetails={setPatientDetails}
                                    refresh={refresh} setRefresh={setRefresh} 
                                />
                            }
                            <button className='btn btn-outline-primary mt-3' onClick={()=>setActiveStep(1)}>Back</button>
                            <button disabled={patientDetails == null} className='btn btn-primary float-end' onClick={doScheduleCheckin}>Check In</button>
                        </StepContent>
                    </Step>
                </Stepper>
            </div>
        </div>
        
    )
}

export default withReceptionLayout(ReceptionScheduleCheckinPage)