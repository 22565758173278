import React,{useState,useEffect,useRef} from 'react'
import withAccountsLayout from '../../withAccountsLayout'
import moment from 'moment';
import DateSelect from '../../../../components/AppInput/DateSelect';
import AppSelect from "../../../../components/AppInput/AppSelect"
import useSystemMasterList from '../../../../hooks/useSystemMasterList';
import accountsCollectionReportServices from '../accountsCollectionReportServices';
import { useReactToPrint } from 'react-to-print';


function StaffCollectionReportPage() {
    const [filter,setFilter] = useState(null)
    const [transactionList,setTransactionList] = useState(null);
    const [userDDList,setUserDDList] = useState(null);
    const [inputUser,setInputUser] = useState(null);

    const getUserDDList = () => {
        accountsCollectionReportServices.getUserDDList().then(res=>{
            setUserDDList(res.data);
        })
    }
    useEffect(()=>{
        getUserDDList();
    },[])

    const [masters,getMasterLabel] = useSystemMasterList(['payment-method']);

    const getTransactionList = () =>{
        if(filter){
            filter.userId = inputUser?.value == undefined ?0: inputUser?.value ;
            accountsCollectionReportServices.getStaffCollectionReport(filter).then(res=>{
                setTransactionList(res.data.collectionReport);
            })
        }
    }

    useEffect(()=>{
        getTransactionList();
    },[filter,inputUser])

    const doTotal = (paymentMethod) =>{
        let total = 0
        if(transactionList){
            transactionList.forEach(transaction => {
                if(transaction.paymentMethod == paymentMethod){
                    total+= transaction.totalAmount;
                }
            });
        }
        return total
    }

    const doGrandTotal = () =>{
        let total = 0
        if(transactionList){
            transactionList.forEach(transaction => {
                total+= transaction.totalAmount;
            });
        }
        return total
    }



    const printRef= useRef()
    const handlePrint = useReactToPrint({
        content: ()=>printRef.current,
        //pageStyle:"@page { size: 6in 3in; margin: 0; padding:0 }, @media print { @page {  size: 6in 3in margin: 0mm !important;  body,html{padding:0px;margin:0px;} } }"
    })


    return (
        <div>
            <div className="row m-2  p-3">
                <div className="col-md-6">
                    <span className=''>Select Date <DateSelect filter={filter} setFilter={setFilter} /></span>
                </div>
                <div className='col-md-3'>
                  {userDDList &&
                    <AppSelect
                        options={[{value:0,label:"All"},...userDDList]}
                        value={inputUser}
                        setValue={setInputUser}
                        label="Select Staff" 
                        optionLabel = "label"
                    />
                  }
                </div>
                <div className='col-md-3'>
                    {transactionList && transactionList.length > 0 &&
                        <button className='btn btn-primary float-end btn-lg' onClick={handlePrint} >Print</button>
                    }
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <table className='table table-striped table-bordered border-dark' ref={printRef}>
                        <thead>
                            <tr>
                                <th>Trx #</th>
                                <th>Collected By</th>
                                <th>Patient</th>
                                <th>Payment Method</th>
                                <th>Date</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactionList && transactionList.map(transaction=>
                                <tr key={transaction.id}>
                                    <td>{transaction.id}</td>
                                    <th>{transaction.staffFirstName} {transaction.staffLastName}</th>
                                    <td>{transaction.firstName} {transaction.lastName}</td>
                                    <td>
                                        <span className={transaction.paymentMethod == 1 ? 'badge bg-primary' : "badge bg-success"}>
                                            {masters && getMasterLabel("payment-method", transaction.paymentMethod)}
                                        </span>
                                        {transaction.transactionNo !== null &&
                                        <div>
                                            <span className="fw-bold">T No : </span>{transaction.transactionNo}
                                        </div>
                                        }
                                    </td>
                                    <td>{ moment(transaction.created_at).format("lll")}</td>
                                    <td>{transaction.totalAmount}</td>
                                </tr>
                            )}
                        </tbody>
                        <tfoot>
                            {masters && masters['payment-method'].map(paymentMethod=>
                                paymentMethod.value < 6 &&
                                <tr key={paymentMethod.value} className='table-info' >
                                    <td colSpan={5}> <span className='float-end'> Total {paymentMethod.label}</span></td>
                                    <td>{doTotal(paymentMethod.value)}</td>
                                </tr>
                            )}
                            <tr className='table-dark'>
                                <td colSpan={5}> <span className='float-end'> Grand Total</span></td>
                                <td>{doGrandTotal()}</td>
                            </tr>
                            <tr>
                                <th colSpan={3} >Received By</th>
                                <th colSpan={3} >
                                    <span className=''><br/>Signature</span>
                                    <span className=''><br/>Date : </span>
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default withAccountsLayout(StaffCollectionReportPage)