import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../../components/FormikControl/FormikControl';
import { Add, Edit } from '@mui/icons-material';
import { useAlert } from '../../../../context/AlertContext';
import feeStructureServices from '../feeStructureServices';


function FeeStructureForm({data,refreshList,masters}) {

    const {AppAlert} = useAlert();
    const [formValues, setFormValues] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);

    const [amountWithGST,setAmountWithGST ] = useState("");


    const initialValues = {
        feeHead:"",
        title:"",
        amount:"",
        gst:"0",
    }

    const validationRule = Yup.object({
        feeHead:Yup.string().required("Required"),
        title:Yup.string().required("Required"),
        amount:Yup.string().required("Required"),
        gst:Yup.string().required("Required"),
        
    });

    const addNew = ()=>{
        setFormValues(initialValues);
        setDialogStatus(true)
    }

    const updateData = ()=>{
        let autoFill = {
            id:data.id,
            feeHead:data.feeHead,
            title:data.title,
            gst:data.gst,
            amount: calculateAmountExcludingGST(data.amount,data.gst)
        }
        setFormValues(autoFill);
        setDialogStatus(true)
    }

    const handleClose  = () =>{
        setDialogStatus(false);
    }

    const onSubmit = (values, onSubmitProps) => {
        console.log(values);
        values.amount = calculateGstAmount(values.amount,values.gst);
        console.log(values);
        
        feeStructureServices.save(values).then(res=>{
            AppAlert(res.msg);
            setDialogStatus(false);
            refreshList()
        }).catch(error=>{
            console.log(error);
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
        
    }

    const calculateGstAmount = (amount,gst) =>{
        amount = parseFloat(amount)
        gst = parseFloat(gst);
        let taxAmount  = (gst* amount)/100;
        return Math.round( amount + taxAmount);
    }


    const calculateAmountExcludingGST = (amount,gst) =>{
        amount = parseFloat(amount);
        gst = parseFloat(gst);
        return ((amount / (100+ gst))*100).toFixed(2);
    }

    const updateAmountForInclusiveGST = (formik) =>{
        let _amount = parseFloat(formik.values.amount);
        let _gst = parseFloat(formik.values.gst);
        let amount = (_amount / (100+ _gst))*100;
        formik.setFieldValue('amount',amount.toFixed(2))
    }

    return (
        <>
            {!data &&
                <Tooltip title="Add New">
                    <button type="button" className="btn btn-primary" onClick={addNew} ><Add/> Add New</button>
                </Tooltip>
            }
            {data &&
                <Tooltip title="Update">
                    <IconButton onClick={updateData} style={{padding:"0px"}}>
                        <Edit/>
                    </IconButton>
                </Tooltip>
            }
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="xs"
                aria-describedby="alert-dialog-slide-description"
            >

                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                    <DialogTitle>{"Fee Structure"}</DialogTitle>
                                    <DialogContent>
                                                <div className="row p-2">
                                                    {masters &&  
                                                        <FormikControl control="autoselect" formik={formik} 
                                                            label="Fee Head" name="feeHead"  
                                                            options={masters['fee-head']} multiple={false} 
                                                            className="col-md-12 p-2"
                                                        />
                                                    }

                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="Fee Title" name="title" 
                                                        className="col-md-12" 
                                                    />
                                                </div>
                                                <div className="row p-2">

                                                    <FormikControl control="input" formik={formik} type="number" 
                                                        label="Charges" name="amount" 
                                                        className="col-md-12 mt-2" 
                                                    />

                                                    <FormikControl control="input" formik={formik} type="number" 
                                                        label="GST" name="gst" 
                                                        className="col-md-12 mt-2" 
                                                    />
                                                </div>
                                                <div className="row p-2">
                                                    <div className='col-md-12 rounded border p-2 mt-3'>
                                                        <div className='d-flex justify-content-between'>
                                                            <div>
                                                                <div class="text-small">
                                                                    Charges + GST  : 
                                                                </div>
                                                                <div className='fw-bold text-success'>
                                                                    {calculateGstAmount(formik.values.amount,formik.values.gst)}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <button type="button" className='btn btn-primary' onClick={()=>updateAmountForInclusiveGST(formik)} >
                                                                    I want {formik.values.amount} Inclusive GST
                                                                </button>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <div class="text-small">
                                                            {/*Define Amount {calculateInclusiveGST(formik)} if you want {formik.values.amount} to make inclusive gst */}
                                                        </div>
                                                    </div>
                                                </div>

                                        <div className="row">
                                        { /*  JSON.stringify(formik.values) */}
                                        </div>

                                        {savingInProgress && <div className="row"><LinearProgress/></div> }
                                    </DialogContent>
                                    <DialogActions>
                                        
                                        
                                        {!savingInProgress && 
                                            <>
                                            <button className="btn btn-success" type="submit">Save</button>
                                            <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                            </>
                                        }
                                        
                                    </DialogActions>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog>

        </>
    )
}

export default FeeStructureForm