import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import QueueLayout from '../components/QueueLayout';
import queueServices from '../queueServices';
import withQueueLayout from '../components/withQueueLayout';
import queueUpdateServices from '../queueUpdateServices';
import { useAlert } from '../../../../context/AlertContext';

import { LinearProgress } from "@mui/material";
import { Formik, Form, isEmptyArray } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../../components/FormikControl/FormikControl';
import useSystemMasterList from '../../../../hooks/useSystemMasterList';



function QueueConfigPage( {queueConfig,getQueueConfig}) {
    

    const {AppAlert} =  useAlert();
    const [formValues, setFormValues] = useState(null);
    const [savingInProgress, setSavingStatus] = useState(false);
    const [masters,getMasterLabel] = useSystemMasterList(['queue-type',"token-payment-time"])      


  
    const onSubmit = (values, onSubmitProps) => {
        setSavingStatus(true);
        queueUpdateServices.updateConfig(values).then(res => {
            getQueueConfig();
            AppAlert(res.msg);
        }).catch(error => {
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    const validationRule = Yup.object({
        title:Yup.string().required("Required"),
        maxFutureDays:Yup.string().required("Required"),
        prefix:Yup.string().required("Required"),
        stations:Yup.string().required("Required"),
        avgProcessTime:Yup.string().required("Required"),
        details:Yup.string().required("Required"),
        tokenPaymentTime:Yup.string().required("Required"),

        //addressRequired:Yup.string().required("Required"),
        //photoRequired:Yup.string().required("Required"),
        //ageRequired:Yup.string().required("Required"),
        //idProofRequired:Yup.string().required("Required"),
        //remarkRequired:Yup.string().required("Required"),
        
    });

    const updateData = (data)=>{
        let autoFill = {
            id:data.id,
            businessId:data.businessId,
            title:data.title,
            queueType:data.queueType,
            maxFutureDays:data.maxFutureDays,
            maxToken:data.maxToken,
            paymentValidity:data.paymentValidity,
            prefix:data.prefix,
            stations:data.stations,
            avgProcessTime:data.avgProcessTime,
            details:data.details,
            status:data.status,
            public:data.public,
            displayStatus:data.displayStatus,
            tokenPaymentTime:data.tokenPaymentTime,

            //otpEmail:data.otpEmail,
            //otpPhone:data.otpPhone,
            //addressRequired:data.addressRequired,
            //photoRequired:data.photoRequired,
            //ageRequired:data.ageRequired,
            //idProofRequired:data.idProofRequired,
            //remarkRequired:data.remarkRequired,
        }
        setFormValues(autoFill);
    }



    useEffect(()=>{
        updateData(queueConfig.queueDetails);
    },[])


    return (
        <div className='card'>
            <div className='card-header'>Queue Configuration</div>
            <div className="card-body mx-2">
                {formValues &&
                <Formik
                        initialValues={formValues}
                        validationSchema={validationRule}
                        onSubmit={onSubmit}
                        enableReinitialize validateOnMount
                    >
                    {
                        (formik) => {
                            return (
                                <Form>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <FormikControl control="switch" formik={formik} 
                                                label="Enable / Disable" name="status" 
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <FormikControl control="switch" formik={formik} 
                                                label="Open For public" name="public" 
                                            />
                                        </div>
                                        <div className="col-md-4 col-xs-12 p-2" >
                                            <FormikControl control="switch" formik={formik} 
                                                label="Show on Display Board" name="displayStatus" 
                                            />
                                            <p className="text-success small"></p>
                                        </div>

                                        {masters && 
                                            <FormikControl control="autoselect" formik={formik} 
                                                label="Token Payment Time" name="tokenPaymentTime"  
                                                options={masters['token-payment-time']} multiple={false} 
                                                className="col-md-3 col-xs-6 p-2"
                                            />
                                        }
                                        {masters && 
                                            <FormikControl control="autoselect" formik={formik} 
                                                label="Queue Type" name="queueType"  
                                                options={masters['queue-type']} multiple={false} 
                                                className="col-md-3 col-xs-6 p-2"
                                            />
                                        }
                                        <div className="col-md-2 col-xs-12 p-2">
                                            <FormikControl control="input" formik={formik} type="number" 
                                                label="Max Appointment Future Days" name="maxFutureDays" 
                                            />
                                        </div>
                                        <div className="col-md-2 col-xs-12 p-2">
                                            <FormikControl control="input" formik={formik} type="number" 
                                                label="Maximum Token for Future Appointment" name="maxToken" 
                                            />
                                        </div>
                                        <div className="col-md-2 col-xs-12 p-2">
                                            <FormikControl control="input" formik={formik} type="number" 
                                                label="Payment Valid for Next" name="paymentValidity" 
                                            />
                                            <p className="text-success small">Days</p>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        
                                        <FormikControl control="input" formik={formik} type="number" 
                                            label="Avg Process Time (Minutes)" name="avgProcessTime" 
                                            className="col-md-4 col-xs-12 p-2" 
                                        />
                                        <div className="col-md-4 col-xs-12 p-2" >
                                        <FormikControl control="input" formik={formik} type="number" 
                                            label="Service Stations" name="stations" 
                                        />
                                        <p className="text-success small">Number of customer you can serve in parallel *</p>
                                        </div>
                                    </div>
                                    <div className="row mt-2">

                                        <FormikControl control="input" formik={formik} type="text" 
                                            label="Queue Display Name" name="title" 
                                            className="col-md-4 col-xs-12 p-2" 
                                        />

                                        <FormikControl control="input" formik={formik} type="text" 
                                            label="Details" name="details" 
                                            className="col-md-4 col-xs-12 p-2" 
                                        />

                                        <FormikControl control="input" formik={formik} type="text" 
                                            label="Token Prefix" name="prefix" 
                                            className="col-md-4 col-xs-12 p-2 " 
                                        />

                                    </div>

                                    
                                    {/*
                                    <div className="row">

                                        <div className="col-md-12 mt-3">
                                            <h3>Form Field Configuration</h3>
                                        </div>

                                        <div className="col-md-6 col-xs-12 p-2" >
                                            <FormikControl control="switch" formik={formik} 
                                                label="Email Number" name="otpEmail" 
                                            />
                                        </div>
                                        <div className="col-md-6 col-xs-12 p-2" >
                                            <FormikControl control="switch" formik={formik} 
                                                label="Phone Number" name="otpPhone" 
                                            />
                                        </div>

                                        <div className="col-md-6 col-xs-12 p-2" >
                                            <FormikControl control="switch" formik={formik} 
                                                label="Customer Photo" name="photoRequired" 
                                            />
                                        </div>

                                        <div className="col-md-6 col-xs-12 p-2" >
                                            <FormikControl control="switch" formik={formik} 
                                                label="Age" name="ageRequired" 
                                            />
                                        </div>

                                        <div className="col-md-6 col-xs-12 p-2" >
                                            <FormikControl control="switch" formik={formik} 
                                                label="Address" name="addressRequired" 
                                            />
                                        </div>

                                        <div className="col-md-6 col-xs-12 p-2" >
                                            <FormikControl control="switch" formik={formik} 
                                                label="Id proff" name="idProofRequired" 
                                            />
                                        </div>

                                        <div className="col-md-6 col-xs-12 p-2" >
                                            <FormikControl control="switch" formik={formik} 
                                                label="Remark" name="remarkRequired" 
                                            />
                                        </div>
                                    </div>
                                    */}
                                    <div className="row">
                                        <div className='col-md-12 mt-2'>
                                            {savingInProgress ? 
                                                <LinearProgress/>
                                                :
                                                <button className="btn btn-outline-primary btn-lg float-end" type="submit">Save</button>
                                            }
                                        </div>
                                    </div>
                                </Form>
                            )
                        }
                    }
                </Formik>
                }
            </div>
        </div>
    )
}

export default withQueueLayout(QueueConfigPage)