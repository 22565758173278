import React,{useState,useEffect,useRef} from 'react'
import withAccountsLayout from '../../withAccountsLayout'
import accountsCollectionReportServices from '../accountsCollectionReportServices'
import { useReactToPrint } from 'react-to-print';

function PatientPendencyReportPage() {
    const [patientList,setPatientList] = useState(null)
    const getPatientList = () =>{
            accountsCollectionReportServices.getPendencyReport().then(res=>{
                setPatientList(res.data);
            })
        
    }
    useEffect(()=>{
        getPatientList();
    },[]);

    const printRef= useRef()
    const handlePrint = useReactToPrint({
        content: ()=>printRef.current,
        //pageStyle:"@page { size: 6in 3in; margin: 0; padding:0 }, @media print { @page {  size: 6in 3in margin: 0mm !important;  body,html{padding:0px;margin:0px;} } }"
    })

    return (
        <>
            <div className='row mt-2'>
                <div className='col-md-6'>
                    <h4 className='text-info'>Pendency Report</h4>
                </div>
                <div className='col-md-6'>
                    <button className='btn btn-outline-primary float-end' onClick={handlePrint} >Print</button>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <table className='table table-striped' ref={printRef}>
                        <thead>
                            <tr>
                                <th>Patient Id</th>
                                <th>Patient Name</th>
                                <th>Account Balance</th>
                                <th>Credit Limit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {patientList?.map(patient=>
                                <tr key={patient.id} className={patient.accountBalance < patient.creditLimit && 'table-danger'} >
                                    <td>{patient.id}</td>
                                    <td>{patient.firstName} {patient.lastName}</td>
                                    <td>{patient.accountBalance}</td>
                                    <td>{patient.creditLimit}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default withAccountsLayout(PatientPendencyReportPage)