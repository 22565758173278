import React,{useState,useEffect} from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import printSettingServices from '../printSettingServices';
import TextInline from '../../../../components/InlineEdit/TextInline';
import AwsImageInline from '../../../../components/InlineEdit/AwsImageInline';

import SectionEditor from '../components/SectionEditor';

function PrintSettingPage() {

    const [printSetting,setPrintSetting] = useState(null);
    

    const [printType,setPrintType] = useState(null);

    const getPrintSetting = () =>{
        if(printType !== null){
            printSettingServices.getDetails(printType).then(res=>{
                setPrintSetting(res.data)
            }).catch(error=>{
    
            })    
        }
        
    }
    
    useEffect(()=>{
        getPrintSetting()
    },[printType])


    
    return (
        <AppLayout>
            <div className='container'>
                {printSetting === null && 
                    <div className='row mt-5'>
                        <div className='col-md-6 text-center'>
                            <button className='btn btn-primary' onClick={()=>setPrintType("token")}>Token Print Setting</button>
                        </div>
                        <div className='col-md-6 text-center'>
                            <button className='btn btn-primary' onClick={()=>setPrintType("gatepass")}>Gate Pass Print Setting</button>
                        </div>
                    </div>
                }

                {printSetting && 
                    <div className='row '>
                        
                        <div className='col-md-12'>
                            {/* JSON.stringify(printSetting) */}
                        </div>

                        <div className='col-md-6'>
                            <div class="d-flex justify-content-center">
                                <span className='fw-bold me-3'>Page Width</span>
                                <div>
                                    <TextInline 
                                        name="pageWidth" 
                                        value={printSetting.pageWidth} 
                                        editId={printSetting.id} 
                                        updateInline={printSettingServices.updateInline}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div class="d-flex justify-content-center">
                                <span className='fw-bold me-3'>Page Height</span>
                                <div>
                                    <TextInline 
                                        name="pageHeight" 
                                        value={printSetting.pageHeight} 
                                        editId={printSetting.id} 
                                        updateInline={printSettingServices.updateInline}
                                    />
                                </div>
                            </div>  
                        </div>

                        <div className='col-md-12'>
                            <hr/>
                            <h3>Banner Image</h3>
                                <AwsImageInline
                                    label="Banner Image"
                                    name="bannerImage"
                                    value={printSetting.bannerImage}
                                    editId={printSetting.id}
                                    updateInline={printSettingServices.updateInline}
                                    path="business/banner"
                                    fileId={"banner"}
                                    onSuccess={getPrintSetting}
                                />
                        </div>


                        <div className='col-md-5'>  
                            <SectionEditor
                                name="leftSection"
                                id={printSetting.id}
                                value={printSetting.leftSection}
                                save={printSettingServices.updateInline}
                                onSuccess={getPrintSetting}
                            />
                        </div>
                        <div className='col-md-2'></div>
                        <div className='col-md-5'>  
                            <SectionEditor
                                name="rightSection"
                                id={printSetting.id}
                                value={printSetting.rightSection}
                                save={printSettingServices.updateInline}
                                onSuccess={getPrintSetting}
                            />
                        </div>
                        <div className='col-md-12'>  
                            <SectionEditor
                                name="footerSection"
                                id={printSetting.id}
                                value={printSetting.footerSection}
                                save={printSettingServices.updateInline}
                                onSuccess={getPrintSetting}
                            />
                        </div>
                    </div>
                }
            </div>
        </AppLayout>
    )
}

export default PrintSettingPage