import React,{useState,useEffect} from 'react'
import withCMSLayout from '../sections/withCMSLayout'
import directoryServices from '../directoryServices';
import DirectoryForm from '../components/DirectoryForm';
import { Switch } from '@mui/material';
import withModuleSetting from '../../../../section/withModuleSetting';

function ManageDirectoryPage() {
  const [directoryList,setDirectoryList] = useState(null);
  const getDirectoryList = () =>{
    directoryServices.getList().then(res=>{
      setDirectoryList(res.data)
    }).catch(error=>{
      console.log(error);
    })
  }
  useEffect(()=>{
    getDirectoryList()
  },[])

  const handleStatusChange = (e,id) =>{
    let payload = {
      id:id,
      status:e.target.checked ? 1 : 0,
    }
    console.log(payload);
    directoryServices.save(payload).then(res=>{
      getDirectoryList();
    })
  }

  return (
    <>
      <div className='row'>
        <div className='col-md-9'>
          <h3>Directory List</h3>
        </div>
        <div className='col-md-3'>
          <span className='float-end'><DirectoryForm refreshList={getDirectoryList} /></span>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <table className='table'>
            <thead>
              <tr>
                <td>Floor</td>
                <td>Location</td>
                <td>Department</td>
              </tr>
            </thead>
            <tbody>
              {directoryList && directoryList.map(directory=>
                <tr key={directory.id}>
                  <td>{directory.floor}</td>
                  <td>{directory.location}</td>
                  <td>{directory.department}</td>
                  <td>
                    <div>
                      <DirectoryForm refreshList={getDirectoryList} data={directory} />
                      <Switch 
                        color="success"
                        checked={directory.status === 1}
                        onChange={(e)=>handleStatusChange(e,directory.id)}
                      />
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default withCMSLayout( withModuleSetting( ManageDirectoryPage,"kioskDirectoryModule"))