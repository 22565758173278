import React, { useState } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useAlert } from '../../../../context/AlertContext';

function SectionEditor({id,name,value,save,onSuccess}) {
    const {AppAlert} = useAlert();
    const [editMode,setEditMode] = useState(false);
    const [newValue,setNewValue] = useState(value);

    const updateSetting = () =>{
        let data = {};
        data[name] = newValue;
        data['id'] = id;
        let payload = {
            data:data,
            type:"text",
        }
        save(payload).then(res=>{
            AppAlert("Updated Successfully");
        }).catch(error => {
            AppAlert(error.msg,"error");
        }).finally(()=>{
            onSuccess();
            setEditMode(false)
        })
    }


    return (
        
                            <div className='row border rounded p-2'>
                                <div className='col-md-12'>
                                    <h3>{name}</h3>
                                </div>
                                <div className='col-md-12'>
                                    {editMode ?
                                        <div>
                                            <CKEditor
                                                editor={ ClassicEditor }
                                                data={newValue}
                                                onReady={ editor => {
                                                    // You can store the "editor" and use when it is needed.
                                                    //console.log( 'Editor is ready to use!', editor );
                                                } }
                                                onChange={ ( event, editor ) => {
                                                    setNewValue(editor.getData());
                                                    
                                                } }
                                                onBlur={ ( event, editor ) => {
                                                    console.log( 'Blur.', editor );
                                                } }
                                                onFocus={ ( event, editor ) => {
                                                    console.log( 'Focus.', editor );
                                                } }
                                            />
                                            <button className='btn btn-primary float-end m-2' onClick={updateSetting}>Save</button>
                                            <button className='btn btn-danger float-end m-2'
                                                onClick={()=>{
                                                    setNewValue("");
                                                }}  
                                            >Clear</button>
                                        </div>
                                    :
                                    <div>
                                        <div dangerouslySetInnerHTML={{ __html: value }} />
                                        <button className='btn btn-primary float-end' onClick={()=>setEditMode(true)}>Edit</button>    
                                    </div>
                                    }
                                </div>
                            </div>
    )
}

export default SectionEditor