import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router-dom'
import withPatientDetailsLayout from '../withPatientDetailsLayout';
import patientServices from '../patientServices';


import PatientBasicDetails from '../components/PatientBasicDetails';
import useSystemMasterList from '../../../hooks/useSystemMasterList';
import PatientForm from '../components/PatientForm';

function PatientDetailsPage() {
    const {patientId} = useParams();
    const [masters,getMasterLabel] = useSystemMasterList(['gender',"document-type"]);
    const [patientDetails,setPatientDetails] = useState(null);
    
    const getPatientDetails = () => {
        patientServices.getById(patientId).then(res=>{
            setPatientDetails(res.data);
        }).catch(error=>{
            console.log(error);
        })
    }
    useEffect(()=>{
        getPatientDetails()
    },[])

    return (
        <div className='row'>
            <div className='col-md-12'>
                {patientDetails && 
                    <div className='card mt-2'>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <span className='float-end'>
                                        <PatientForm  onSuccess={getPatientDetails} data={patientDetails} />
                                    </span>
                                </div>
                            </div>
                            <PatientBasicDetails 
                                patientDetails={patientDetails} 
                                onSuccess={getPatientDetails} 
                                masters={masters} 
                                getMasterLabel={getMasterLabel} 
                            />
                        </div>
                    </div>
                }
            </div>
        </div>
        
    )
}

export default withPatientDetailsLayout(PatientDetailsPage)