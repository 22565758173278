import React, { useEffect, useState } from 'react'
import withReceptionLayout from '../../withReceptionLayout'
import { useParams } from 'react-router-dom'
import { useAlert } from '../../../../context/AlertContext';
import ReceiveTokenPayment from '../../../accounts/sections/ReceiveTokenPayment';
import PrintOPDSlip from '../components/PrintOPDSlip';

import useSystemMasterList from '../../../../hooks/useSystemMasterList'
import receptionCheckinServices from '../receptionCheckinServices'
import patientServices from '../../../patient/patientServices';
import { Dialog, DialogContent } from '@mui/material';
import moment from 'moment';

function TokenPaymentPage() {
    const {patientId} = useParams();
    const {AppAlert} = useAlert()
    const [inputPatientId,setInputPatientId]    =   useState(patientId?patientId:"");
    const [patientTokenList,setPatientTokenList] = useState(null);

    
    
    const getPatientTokenList = () => {
        if(inputPatientId!==""){
            receptionCheckinServices.getCustomerTokenList(inputPatientId).then(res=>{
                setPatientTokenList(res.data);
            }).catch(error=>{
                AppAlert(error.msg,"error")
            })
        }
    }

    const handlePatintIdKeydown = (e) =>{
        if (e.key === 'Enter') {
            console.log('do validate');
            getPatientTokenList();
        }
    }

    useEffect(()=>{
        getPatientTokenList();
    },[])

    // Search By Phone Number 
    const [dialogStatus,setDialogStatus]     =  useState(false);
    const [inputPhoneNo,setInputPhoneNo] = useState("");
    const [searchList,setSearchList] = useState(null);
    const searchByPhoneNo = () =>{
        patientServices.searchByPhone(inputPhoneNo).then(res=>{
            setSearchList(res.data);
        }).catch(error=>{
        })
    }
    const searchSelect = (id) =>{
        setInputPatientId(id);
        receptionCheckinServices.getCustomerTokenList(id).then(res=>{
            setPatientTokenList(res.data);
        })
        setDialogStatus(false);
    }
    const handlePatintPhoneNoKeydown = (e) =>{
        if (e.key === 'Enter') {
            console.log('do validate');
            searchByPhoneNo();
        }
    }
    return (
        <>
            <div className='row'>
                <div className='col-md-6'>
                    <div className="form-floating">
                        <input type="text" className="form-control"
                            value={inputPatientId} onChange={(e)=>{setInputPatientId(e.target.value)}} onKeyDown={handlePatintIdKeydown}
                        />
                        <label >Enter Patient Id</label>
                    </div>
                </div>
                <div className='col-md-6'>
                    <button type="button" className='btn btn-link me-2' onClick={()=>setDialogStatus(true)}>Search By Phone</button>
                </div>
            </div>
            
            <div className='row mt-3'> 
                {patientTokenList && patientTokenList.length === 0 && 
                    <div className='col-md-12 text-center'><h3 className='text-success'>No Token Found </h3></div>
                }
            
                {patientTokenList && patientTokenList.length > 0 && patientTokenList.map(patientToken=>
                    <div className='col-md-12' key={patientToken.id}>
                        <PrintOPDSlip patientToken={patientToken} onSuccess={getPatientTokenList} />
                    </div>
                )}
            </div>
            <Dialog
                    open={dialogStatus}
                    keepMounted
                    onClose={()=>{setDialogStatus(false)}}
                    fullWidth={true}
                    maxWidth="md"
                >
                <DialogContent>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className="form-floating">
                                <input type="text" className="form-control"
                                    value={inputPhoneNo} onChange={(e)=>{setInputPhoneNo(e.target.value)}}
                                    onKeyDown={handlePatintPhoneNoKeydown}
                                />
                                <label >Enter PhoneNo </label>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <button className='btn btn-primary' onClick={searchByPhoneNo} >Search</button>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            {searchList && searchList.length > 0 &&
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>S/O, D/O, W/O</th>
                                            <th>Patient ID</th>
                                            <th>User Since</th>
                                            <th>Last Upadate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {searchList.map(patient =>
                                            <tr onClick={()=>searchSelect(patient.id)} >
                                                <td>{patient.firstName} {patient.lastName}</td>
                                                <td>{patient.careOf}</td>
                                                <td>P-{patient.id}</td> {moment}
                                                <td>{ moment(patient.created_at).format("ll") }</td>
                                                <td>{ moment(patient.updated_at).format("ll") }</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            }
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default withReceptionLayout(TokenPaymentPage)