import React, { useEffect, useState } from 'react'
import withReceptionLayout from '../../withReceptionLayout'
import PaymentServicesForm from '../../../accounts/sections/PaymentServicesForm';
import PatientAccountBalance from '../../../patient/components/PatientAccountBalance';
import PatientSearchComponent from '../../../patient/components/PatientSearchComponent';

function PaymentAndServicesPage() {
    const [patientDetails,setPatientDetails] = useState(null);
    const [refresh,setRefresh] = useState(false);

    const onSuccess = () =>{
        setRefresh(true);
    }

    return (
        <div className=''>
            <div className=''>
                <div className='row'>
                    <div className='col-md-12'>
                        <PatientSearchComponent
                            patientDetails={patientDetails} setPatientDetails={setPatientDetails} 
                            refresh={refresh} setRefresh={setRefresh}
                        />
                    </div>
                </div>
                {patientDetails &&
                    <div className='row'>
                        <div className='col-md-12'>
                            <PatientAccountBalance patientId={patientDetails.id} info="details" onSuccess={onSuccess} />
                        </div>
                    </div>
                }
            </div>
        </div>    
        
    )
}

export default withReceptionLayout(PaymentAndServicesPage)