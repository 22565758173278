import api from "../../../api/api";


const getCheckinQueueList = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/doctor/checkin/queue-list").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :"res.data.msg"
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}


const getCheckinPendingList = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/doctor/checkin/pending-list").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :"res.data.msg"
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}

const callCheckinVisitor = () =>{
    return new Promise((resolve, reject)=>{
        api.get("doctor/checkin/call-visitor").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}

const callOnHoldVisitor = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("doctor/checkin/call-onhold-visitor",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}

const updateCheckinStatus = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("doctor/checkin/update-status",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}

const transferToken = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("doctor/checkin/transfer-token",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}


const getAvailableSlots = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/appointment/checkin/available-slots",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const doScheduleCheckin = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/appointment/checkin/do-schedule-checkin",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const doctorCheckinServices = {
    getCheckinQueueList,
    getCheckinPendingList,
    callCheckinVisitor,
    updateCheckinStatus,
    callOnHoldVisitor,
    transferToken,

    getAvailableSlots,
    doScheduleCheckin,

}
export default doctorCheckinServices;