import React, { useState,useEffect, useRef } from 'react'
import withReceptionLayout from '../../withReceptionLayout'
import moment from 'moment';
import useSystemMasterList from '../../../../hooks/useSystemMasterList';
import { useReactToPrint } from 'react-to-print';
import MyCollectionReportSection from '../../../accounts/reports/sections/MyCollectionReportSection';

function ReceptionCollectionReportPage() {
    return (
        <MyCollectionReportSection />
    )
}

export default withReceptionLayout(ReceptionCollectionReportPage)