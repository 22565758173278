import React, { useEffect, useState } from 'react'
import AppLayout from '../../../layout/components/AppLayout'
import { Link } from 'react-router-dom'
import AppSelect from '../../../components/AppInput/AppSelect'

function DisplayDashboardPage() {

    const [voiceList,setVoiceList]      = useState(null)
    const [voiceIndex,setVoiceIndex]    = useState(null);
    const getVoiseList = async () =>{
        
            let _voices = speechSynthesis.getVoices();
            let _voiceList = [];
            console.log()
            _voices.forEach((voice,i)=>{
                _voiceList.push({value:i,label:voice.voiceURI+" - "+voice.lang})
            })
            console.log(_voiceList);
            setVoiceList(_voiceList);
        
            if(voiceIndex == null){
                setVoiceIndex(sessionStorage.getItem('voiceIndex'));
            }
        
    }

    useEffect(()=>{
        if(voiceList === null || voiceList.length ==0){
            getVoiseList();
        }
    },[voiceList])

    useEffect(()=>{
        getVoiseList()
    },[])


    const [selectedVoice,setSelectedVoice] = useState(null)



    useEffect(()=>{
        if(selectedVoice !== null){
            sessionStorage.setItem('voiceIndex',selectedVoice.value)
            setVoiceIndex(selectedVoice.value)
        }
    },[selectedVoice])

    const speak = () => {
        let text = "Hello I am Ready";
        console.log("here", text);
        let voiceIndex = sessionStorage.getItem('voiceIndex')
        var utterance = new SpeechSynthesisUtterance();
        utterance.text = text;
        utterance.voice = speechSynthesis.getVoices()[voiceIndex];
        speechSynthesis.speak(utterance);
    }


    

    

    return (
        <AppLayout>
            <div className='container'>
                {voiceIndex === null &&
                    <div className='row mt-3 p-5'>
                        <div className='col-md-6 offset-md-3'>
                            <h3>Selecte a Language</h3>
                        </div>
                        <div className='col-md-6 offset-md-3'>
                            {voiceList &&
                                <AppSelect
                                    options={voiceList}
                                    value={selectedVoice}
                                    setValue={setSelectedVoice}
                                    label="Select Language" 
                                    optionLabel = "label"
                                />
                            }
                        </div>
                    </div>
                }
                
                {  voiceIndex !== null &&
                    <div className='row'>
                        
                            {voiceList && 
                                <div className='col-md-12'>
                                    <div className='d-flex justify-content-center p-3'>
                                        <h6 className='mt-2 text-success'>Selected Language : {voiceList[voiceIndex]?.label}</h6>
                                        <button type="button" className='btn btn-primary ms-2' onClick={()=>setVoiceIndex(null)} >Change Language</button>
                                    </div>
                                </div>
                            }
                    </div>
                }
                    <div className='row'>
                
                        <div className='col-md-6 '>
                            <Link to="/display/tv/waiting-list" className="btn btn-lg w-100 btn-outline-primary">TV Display 1</Link>
                            <h5 className='text-center mt-3 text-success'>Token with Waiting List</h5>
                        </div>
                        <div className='col-md-6'>
                            <Link to="/display/tv/current-token" className="btn btn-lg w-100 btn-outline-primary">TV Display 2</Link>
                            <h5 className='text-center mt-3 text-success'>Current Token Only</h5>
                        </div>

                        <div className='col-md-6 mt-5'>
                            <Link to="/display/tv/video-ads" className="btn btn-lg w-100 btn-outline-primary">TV Display 3 </Link>
                            <h5 className='text-center mt-3 text-success'>Video Ads Only</h5>
                        </div>

                        <div className='col-md-6 mt-5'>
                            <Link to="/display/tv/banner-ads" className="btn btn-lg w-100 btn-outline-primary">TV Display 4 </Link>
                            <h5 className='text-center mt-3 text-success'>Banner And News</h5>
                        </div>

                        <div className='col-md-6 mt-5'>
                            <Link to="/display/tv/mini-display" className="btn btn-lg w-100 btn-outline-primary">TV Display 5 </Link>
                            <h5 className='text-center mt-3 text-success'>Mini Display</h5>
                        </div>

                        <div className='col-md-6 mt-5'>
                            <Link to="/display/tv/dual-display" className="btn btn-lg w-100 btn-outline-primary">TV Display 6 </Link>
                            <h5 className='text-center mt-3 text-success'>Dual Display</h5>
                        </div>

                        <div className='col-md-3'>
                            { <button className='btn btn-primary' onClick={speak} >Test Speach</button> }
                        </div>
                    </div>
            
            </div>
        </AppLayout>
    )
}

export default DisplayDashboardPage