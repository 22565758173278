import React, { useState,useEffect, useRef } from 'react'
import { useAlert } from '../../../../context/AlertContext'
import useSystemMasterList from '../../../../hooks/useSystemMasterList';
import feeStructureServices from '../feeStructureServices';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import FeeStructureForm from '../components/FeeStructureForm';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import withAccountsLayout from '../../withAccountsLayout';


const CellActions = (props) =>{
    return(
        props.data.feeHead !==1 &&
            <>
                <FeeStructureForm masters={props.masters} refreshList={props.refreshList} data={props.data} />
                <ConfirmDialog id={props.data.id} confirm={ ()=>props.remove(props.data.id,props.masterName)} >Are you Sure You want to remove</ConfirmDialog>
            </>
    )
  }

function FeeStructureListPage() {

    const {AppAlert} = useAlert();
    const gridRef = useRef();
    const [masters,getMasterLabel] = useSystemMasterList(['fee-head']);
    const [feeStructureList,setFeeStructureList] = useState(null);

    const getFeeStructureList = () =>{
        feeStructureServices.getList().then(res=>{
            setFeeStructureList(res.data);
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }

    const [defaultColDef] = useState({
        floatingFilter: true,
        filterParams: {
          buttons: ['apply'],
        },
        resizable: true,
        width:120,
        filter:true,
        sortable: true,
        cellStyle: { 'textTransform': 'uppercase' },
    });

    const rowClassRules =  {
        'bg-warning' : params => params.data.feeHead == 1,
    }


    const calculateAmountExcludingGST = (amount,gst) =>{
        amount = parseFloat(amount);
        gst = parseFloat(gst);
        return ((amount / (100+ gst))*100).toFixed(2);
    }
    const [columnDefs,setColumnDef] = useState();
    const prepairColumnDef = () => {
        if(masters!==null){
            let _columnDefs = [
                {field:"id",headerName: "ID"},
                {field:"feeHead",headerName:"Fee Head", valueGetter: params =>getMasterLabel('fee-head',params.data.feeHead)},
                {field:"title",headerName: "Fee Title",width:250},
                {headerName: "Charges", valueGetter:params=>{
                    //let taxAmount  = (params.data.gst*params.data.amount)/100;
                    return calculateAmountExcludingGST(params.data.amount,params.data.gst);
                }},
                {field:"gst",headerName: "GST"},
                {field:"amount",headerName: "C + Charges"},
                {headerName:"Action", width:"200", valueGetter: params => params.data.title,
                    cellRenderer: CellActions,
                    cellRendererParams: {
                        masters:masters,
                        refreshList:getFeeStructureList,
                        remove:remove
                    },
                },      
            ];
            setColumnDef(_columnDefs);
        }
    }

    const remove = (id)=>{
        feeStructureServices.remove(id).then(res=>{
          getFeeStructureList();
          AppAlert("Removed Successfully","error")
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
      }

    useEffect(()=>{    
        prepairColumnDef();
    },[masters]);

    return (
        
                <div className='row'>
                    <div className='col-md-12 mb-2'>
                        <span className='float-end'><FeeStructureForm masters={masters} refreshList={getFeeStructureList} /></span>
                    </div>
                    <div className='col-md-12'>
                        <div className="ag-theme-alpine" style={{height: 400}}>
                            {masters &&
                            <AgGridReact
                                ref={gridRef}
                                rowData={feeStructureList}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                animateRows={true}
                                rowClassRules={rowClassRules}
                                onGridReady={getFeeStructureList}
                                >
                            </AgGridReact>
                            }
                        </div>
                    </div>
                </div>
            
    )
}

export default withAccountsLayout(FeeStructureListPage)