import api from "../../../api/api";


const getFeeList = (feeHead) =>{
    return new Promise((resolve, reject)=>{
        api.get("/accounts/fee-structure/dd/list/"+feeHead).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const addServicesPayment = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/accounts/payment/add-services",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
        })
    })
}

const tokenPayment = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/accounts/payment/token",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const accountSectionServices = {
    tokenPayment,
    addServicesPayment,
    getFeeList
}
export default accountSectionServices;