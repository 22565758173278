import React from 'react'
import AppLayout from '../../../../layout/components/AppLayout'

function AdminDashboardPage() {
    return (
        <AppLayout>
            <div>AdminDashboardPage</div>
        </AppLayout>
    )
}

export default AdminDashboardPage