import React,{useState,useEffect,useRef} from 'react'
import withAccountsLayout from '../../withAccountsLayout'
import moment from 'moment';
import DateSelect from '../../../../components/AppInput/DateSelect';
import AppSelect from "../../../../components/AppInput/AppSelect"
import useSystemMasterList from '../../../../hooks/useSystemMasterList';
import accountsCollectionReportServices from '../accountsCollectionReportServices';
import { useReactToPrint } from 'react-to-print';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

function AccountsTransactionReportPage() {

  const gridRef = useRef();
  const [filter,setFilter] = useState(null)
  const [transactionList,setTransactionList] = useState(null);
  const [userDDList,setUserDDList] = useState(null);
  const [inputUser,setInputUser] = useState(null);
  const getUserDDList = () => {
      accountsCollectionReportServices.getUserDDList().then(res=>{
          setUserDDList(res.data);
      })
  }
  useEffect(()=>{
      getUserDDList();
  },[])
  const [masters,getMasterLabel] = useSystemMasterList(['payment-method','fee-head']);

  const getTransactionList = () =>{
      prepairColumnDef();
      if(filter){
          filter.userId = inputUser?.value == undefined ?0: inputUser?.value ;
          accountsCollectionReportServices.getTransactionReport(filter).then(res=>{
              setTransactionList(res.data);
          })
      }
  }
  useEffect(()=>{
      getTransactionList();
  },[filter,inputUser]);




  const [defaultColDef] = useState({
    floatingFilter: true,
    filterParams: {
      buttons: ['apply'],
    },
    resizable: true,
    width:150,
    filter:true,
    sortable: true,
    cellStyle: { 'textTransform': 'uppercase' },
  });

  const rowClassRules =  {
    'bg-success' : params => params.data.transactionType == 1,
    'bg-danger' : params => params.data.transactionStatus == 0,
  }

  const calculateAmountExcludingGST = (amount,gst) =>{
    amount = parseFloat(amount);
    gst = parseFloat(gst);
    return ((amount / (100+ gst))*100).toFixed(2);
}

const [columnDefs,setColumnDef] = useState();
    const prepairColumnDef = () => {
    if(masters!==null){
        let _columnDefs = [
            {field:"id",headerName: "Transaction #"},
            {field:"feeHead",headerName:"Service Head", valueGetter: params =>getMasterLabel('fee-head',params.data.feeHead)},
            {field:"feeStructureTitle",headerName:"Sevice"},
            {field:"staffUserId",headerName:"Staff Id",width:80 },
            {headerName:"Staff Name", valueGetter: params =>params.data.staffFirstName+" "+params.data.staffLastName},
            {field:"patientId",headerName:"Patient Id",width:80 },
            {headerName:"Patient Name ", valueGetter: params =>params.data.firstName+" "+params.data.lastName},
            {headerName:"T.Type ", valueGetter: params =>params.data.transactionType == 1 ? "Credit" : "Debit"},
            {headerName:"T.Status ", valueGetter: params =>params.data.transactionStatus == 0 ? "Cancelled" : "OK"},
            {field:"qty",headerName: "Qty",width:80},
            {headerName: "Charges", valueGetter:params=>{
                //let taxAmount  = (params.data.gst*params.data.amount)/100;
                return calculateAmountExcludingGST(params.data.totalAmount,params.data.gst);
            }},
            {field:"gst",headerName: "GST", valueGetter : params => params.data.gst == 0 ? "nil": params.data.gst+"%"},
            {field:"totalAmount",headerName: "GST + Charges"},     
        ];
        setColumnDef(_columnDefs);
    }
  }

  const onBtnExport = () => {
    gridRef.current.api.exportDataAsCsv();
  }
  
  return (
    <div>
      <div className="row m-2  p-3">
          <div className="col-md-6">
              <span className=''>Select Date <DateSelect filter={filter} setFilter={setFilter} /></span>
          </div>
          <div className='col-md-3'>
            {userDDList &&
              <AppSelect
                  options={[{value:0,label:"All"},...userDDList]}
                  value={inputUser}
                  setValue={setInputUser}
                  label="Select Staff" 
                  optionLabel = "label"
              />
            }
          </div>
          <div className='col-md-3'>
              {transactionList && transactionList.length > 0 &&
                  <button className='btn btn-primary float-end btn-lg' onClick={onBtnExport} >Export</button>
              }
          </div>
      </div>
      <div className="row m-2  p-3">
                      <div className='col-md-12'>
                        <div className="ag-theme-alpine" style={{height: 600}}>
                            {masters &&
                            <AgGridReact
                                ref={gridRef}
                                rowData={transactionList}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                animateRows={true}
                                rowClassRules={rowClassRules}
                                onGridReady={getTransactionList}
                                >
                            </AgGridReact>
                            }
                        </div>
                    </div>
      </div>
    </div>
  )
}

export default withAccountsLayout(AccountsTransactionReportPage)