import React, { useEffect, useState } from 'react'
import withReceptionLayout from '../../withReceptionLayout'
import { useAlert } from '../../../../context/AlertContext';
import receptionCheckinServices from '../receptionCheckinServices';

import { Stepper,Step, StepLabel, StepContent, Slide } from '@mui/material';
import PatientSearchComponent from '../../../patient/components/PatientSearchComponent';
import { useNavigate } from 'react-router-dom';
import { useSocket } from '../../../../context/SocketContext';

function ReceptionCheckinPage() {
    
    const {AppAlert}  = useAlert();
    const {socket} = useSocket();
    const [activeStep,setActiveStep] = useState(0);

    const [selectedQueueId,setSelectedQueueId] = useState(null);
    const [selectedQueueTitle,setSelectedQueueTitle] = useState(null);
    const [selectedServiceId,setSelectedServiceId] = useState(null);
    const [selectedServiceTitle,setSelectedServiceTitle] = useState(null);

    const [queueList,setQueueList]  = useState(null);
    const [queueServiceList,setQueueServiceList] = useState(null);

    const getQueueList = () =>{
        receptionCheckinServices.getQueueList().then(res=>{
            setQueueList(res.data);
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }

    useEffect(()=>{
        getQueueList();
    },[])


    const selectQueue = (queue) =>{
        setSelectedQueueId(queue.id);
        setSelectedQueueTitle(queue.title)
        setSelectedServiceId(null);
        setSelectedServiceTitle(null);
        setActiveStep(1)
    }

    const selectService = (service) =>{
        setSelectedServiceId(service.id);
        setSelectedServiceTitle(service.title);
        setActiveStep(2)
    }

    const getQueueServiceList = () =>{
        if(selectedQueueId !== ""){
            receptionCheckinServices.getServiceListByQueueId(selectedQueueId).then(res=>{
                setQueueServiceList(res.data);
            }).catch(error=>{
                AppAlert(error.msg,"error")
            })
        }
    }
    
    useEffect(()=>{
        getQueueServiceList()
    },[selectedQueueId])


    const [patientDetails,setPatientDetails] = useState(null);
    const [refresh,setRefresh] = useState(null);


    const navigate = useNavigate();
    const doCheckin = () =>{
        let payload = {
            queueId:selectedQueueId,
            serviceId:selectedServiceId,
            patientId:patientDetails.id,
        }
        receptionCheckinServices.doCheckin(payload).then(res=>{
            console.log(res);
            AppAlert(res.msg,"info");
            if(res.tokenPaymentTime == 1){
                socket.emit("send_new_token",{message:"new token received"})
            }
            navigate("/reception/token-payment/"+patientDetails.id)
        }).catch(error=>{
            console.log(error);
            AppAlert(error.msg,"error");
        })
    }

    return (
        <div className='card'>
            <div className='card-header'>Checkin</div>
            <div className='card-body'>
                <Stepper activeStep={activeStep} orientation="vertical" >
                    <Step>
                        <StepLabel>{selectedQueueTitle ? selectedQueueTitle : "Select Queue"}</StepLabel>
                        <StepContent>
                            {queueList &&
                                <div className='list-group'>
                                    {queueList.map(queue=>
                                        <span key={queue.id}>
                                            {queue.isOpenNow == true ?
                                                    <button type="button" onClick={()=>selectQueue(queue)} class="list-group-item list-group-item-action">
                                                        <div>{queue.title}</div>
                                                        <div className='text-success'>Waiting Time {queue.waitingTime} Minutes</div>
                                                    </button>
                                                :
                                                    <li class="list-group-item list-group-item-action">
                                                        <div>{queue.title}</div>
                                                        <div className='text-danger'>Currently Closed</div>
                                                    </li>
                                            }                                            
                                        </span>
                                    )}
                                </div>
                            }
                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel>{selectedServiceTitle ? selectedServiceTitle : "Select Queue"}</StepLabel>
                        <StepContent>
                            {selectedQueueId && queueServiceList &&
                                <div className='list-group'>
                                    {queueServiceList.map(queueService=>
                                        <span key={queueService.id}>
                                            <button type="button" onClick={()=>selectService(queueService)} class="list-group-item list-group-item-action">{queueService.title}</button>
                                        </span>
                                    )}
                                </div>
                            }
                            <button className='btn btn-outline-primary mt-3' onClick={()=>setActiveStep(0)}>Back</button>
                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel>Patient Details</StepLabel>
                        <StepContent>
                            {selectedQueueId && selectedServiceId &&
                                <PatientSearchComponent
                                    patientDetails={patientDetails} setPatientDetails={setPatientDetails}
                                    refresh={refresh} setRefresh={setRefresh} 
                                />
                            }
                            <button className='btn btn-outline-primary mt-3' onClick={()=>setActiveStep(1)}>Back</button>
                            <button disabled={patientDetails==null}  className='btn btn-primary float-end' onClick={doCheckin}>Check In</button>
                        </StepContent>
                    </Step>
                </Stepper>
            </div>
        </div>
    )
}

export default withReceptionLayout(ReceptionCheckinPage)


/*

                                <PatientCheckinForm
                                    queueId={selectedQueueId}
                                    serviceId={selectedServiceId}
                                />              

*/