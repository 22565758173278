import { Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import AppSelect from '../../../components/AppInput/AppSelect'


import { useAlert } from '../../../context/AlertContext'
import { useSocket } from '../../../context/SocketContext'
import accountSectionServices from './accountSectionServices'

function ReceiveTokenPayment({patientToken, onSuccess,masters}) {

    const {socket} = useSocket();
    const {AppAlert} = useAlert();
    const [dialogStatus,setDialogStatus]    = useState(false)

    const [selectedFee,setSelectedFee] = useState(null);
    const [paymentMethod,setPaymentMethod] = useState(null);
    const [transactionNo, setTransactionNo] = useState("");

    const [feeList,setFeeList] = useState(null)
    const getFeesList = (feeHead) =>{
        accountSectionServices.getFeeList(feeHead).then(res=>{
            setFeeList(res.data);
        }).catch(error=>{
            AppAlert("Somthing Went Wrong","error")
        })
    }

    const [savingInProcess, setSavingStatus] = useState(false);
    const submitPayment = () =>{
        let payload ={
            tokenId:patientToken.id,
            patientId:patientToken.patientId,
            fee : selectedFee,
            paymentMethod:paymentMethod.value,
            transactionNo : transactionNo, 
        }
        setSavingStatus(true);
        accountSectionServices.tokenPayment(payload).then(res=>{
            setDialogStatus(false);
            AppAlert(res.msg);
            socket.emit("send_new_token",{message:"new token received"})
            onSuccess();
        }).catch(error=>{
            AppAlert("Somthing Went Wrong","error");
            setDialogStatus(false);
        }).finally(()=>{
            setSavingStatus(false);
        })
    }
    

    return (
        <div className=''>
            {patientToken.paymentStatus == 0 ?
                <button type='button' className='btn btn-primary' onClick={()=>setDialogStatus(true)}>Receive Payment</button>
                :
                <span className='badge bg-success'>Payment Done</span>
            }
            
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={()=>{setDialogStatus(false)}}
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle>OPD Charges</DialogTitle>
                <DialogContent>
                    <div className='row mt-3 mb-3'>
                        <div className='col-md-6'>
                            {masters &&
                                <AppSelect
                                    options={[{value:"0",label:"Select Payment Method"},...masters['payment-method']]}
                                    value={paymentMethod}
                                    setValue={setPaymentMethod}
                                    label="Payment Method" 
                                    optionLabel = "label"
                                />
                            }
                        </div>
                        <div className='col-md-6'>
                            <div className="form-floating">
                                    <input type="text" className="form-control"
                                        value={transactionNo}
                                        onChange={(e)=>setTransactionNo(e.target.value)}
                                    />
                                    <label>Transaction #</label>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <div className='row my-3'>
                            <div className='col-md-12 text-center'>
                                <button className='btn btn-primary me-2' onClick={()=>getFeesList(2)} >Consultancy Fees</button>
                                <button className='btn btn-info me-2' onClick={()=>getFeesList(3)} >Lab Fees</button>
                            </div>
                        </div>
                        <table className="table table-hover table-striped mt-3">
                            <tbody>
                                {feeList && feeList.map(fee=>
                                    <tr key={fee.id} onClick={()=>setSelectedFee(fee)}
                                        className={selectedFee && selectedFee.id === fee.id && 'table-success'}
                                    >
                                        <td>{fee.feeHeadTitle}</td>
                                        <td>{fee.title}</td>
                                        <td>
                                            <button type="button"
                                                className={selectedFee && selectedFee.id === fee.id ? 'btn btn-success' :" btn btn-outline-success"}
                                            >    
                                                {fee.amount}
                                            </button> 
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className='row'>
                        {feeList && feeList.length > 0 &&
                            <div className='col-md-12'>
                                {savingInProcess ? 
                                    <LinearProgress/>    
                                    :
                                    <>
                                    <button type='button' 
                                        disabled={paymentMethod === null || paymentMethod.value == "0" || selectedFee === null}
                                        className='btn btn-primary float-end'
                                        onClick={submitPayment}
                                    >
                                        Submit
                                    </button>
                                        <button className='btn btn-outline-primary' onClick={()=>setDialogStatus(false)} >Cancel</button>
                                    </>
                                }
                            </div>
                        }
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default ReceiveTokenPayment