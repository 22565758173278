import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print'
import ReceiveTokenPayment from '../../../accounts/sections/ReceiveTokenPayment';
import useSystemMasterList from '../../../../hooks/useSystemMasterList';
import receptionCheckinServices from '../receptionCheckinServices';

function PrintOPDSlip({patientToken, onSuccess}) {
    
    const [masters] = useSystemMasterList(['payment-method']);
    const printRef= useRef();

    const [printSetting,setPrintSetting] = useState(null)

    const [pageStyle,setPageStyle] = useState("")
    const getPrintSetting = () =>{
        receptionCheckinServices.getPrintSetting("token").then(res=>{
            setPrintSetting(res.data)
        })
    }

    useEffect(()=>{
        if(printSetting !== null){
            let _pageStyle = "@page { size: "+printSetting.pageWidth+" "+printSetting.pageHeight+"; margin: 0; padding:0 }, @media print { @page {  size: "+printSetting.pageWidth+" "+printSetting.pageHeight+"; margin: 0mm !important;  body,html{padding:0px;margin:0px;} } }";
            console.log(_pageStyle);
            setPageStyle(_pageStyle);
        } else {
            setPageStyle("@page { size: 6in 4in; margin: 0; padding:0 }, @media print { @page {  size: 6in 4in margin: 0mm !important;  body,html{padding:0px;margin:0px;} } }")
        }
    },[printSetting])
    useEffect(()=>{
        getPrintSetting()
    },[])

    const handlePrint = useReactToPrint({
        content: ()=>printRef.current,
        pageStyle:pageStyle,
        onAfterPrint:  ()=>{console.log(pageStyle)} 
    })

    return (
        printSetting &&
        <div className='border border-dark mb-2 p-2'>
            <div className='row'>
                <div className='col-md-12'>
                    {/*JSON.stringify(printSetting) */}
                </div>
                <div className='col-md-12'>
                    {(patientToken.tokenPaymentTime == 1 || patientToken.paymentStatus == 1 ) &&
                        <button className='btn btn-outline-primary btn-lg me-2 float-end ms-2' onClick={handlePrint} >Print</button>
                    }
                    <span className='-float-start'>
                        {patientToken.tokenPaymentTime == 1 ?  // For Pay Later   
                            <span className='text-success'>Post Paid</span>
                            :
                            <span className='text-danger'>Prepaid</span>
                        }
                        <span className='float-end'>
                            <ReceiveTokenPayment
                                    patientToken={patientToken} 
                                    onSuccess={onSuccess}
                                    masters={masters}
                            />
                            <span className='badge bg-info'>{patientToken.statusTitle}</span>
                        </span>
                    </span>
                </div>
            </div>
            
            <div className='row' >
                <div className='col-12' >
                    <table className='table' ref={printRef}  >
                        <tbody>
                            <tr>
                                <td colSpan={4} style={{padding:0}}>
                                    {printSetting.bannerImage!==null &&
                                        <img src={printSetting.bannerImage} style={{height:".5in", width:"100%"}} />
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>Patient Name
                                </th>
                                <td>
                                    {patientToken.firstName}  {patientToken.lastName}<br/>
                                    {patientToken.gender === 1 ? "Male" : "Female"}
                                </td>
                                <th>Patient ID </th>
                                <td>
                                    <span className='barcode'>P-{patientToken.patientId}</span>                    
                                </td>   
                            </tr>
                            {patientToken.address != "" && patientToken.address != null &&
                                <tr>
                                    <th>Patient Address</th>
                                    <td colSpan={3}>{patientToken.address}</td>
                                </tr>
                            }
                            
                            <tr>
                                <th>Phone </th>
                                <td>{patientToken.phone}</td>
                                <th>Token #</th>
                                <td>
                                    <span className='p-2 me-1 fw-bold' > {patientToken.prefix} - {patientToken.tokenNo}</span> ({moment(patientToken.checkinDate).format("ll")})
                                    {patientToken.paymentValidity > 0 &&
                                        <>
                                            <br/>
                                            <span className='p-2 me-1 fw-bold' >Fee Valid Till : </span>{moment(patientToken.checkinDate).add(patientToken.paymentValidity, 'day').format("LL")}                                            
                                        </>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>DOB</th>
                                <td>{moment(patientToken.dob).format("ll")}<br/> <span className='fw-bold'>Age ({moment().diff(patientToken.dob,"years")})</span> </td>
                                <th>Department</th>
                                <td>{patientToken.queueTitle}<br/>{patientToken.serviceTitle}</td>
                            </tr>
                            <tr >
                                <td style={{borderBottomWidth:0}}><div dangerouslySetInnerHTML={{ __html: printSetting.leftSection }} /></td>
                                <td style={{borderBottomWidth:0}}></td>
                                <td style={{borderBottomWidth:0}}></td>
                                <td style={{borderBottomWidth:0}} ><div className='float-end' dangerouslySetInnerHTML={{ __html: printSetting.rightSection }} /></td>
                            </tr>
                            {/*
                            <tr>
                                <th colSpan={4}>
                                    {JSON.stringify(patientToken)}
                                </th>
                            </tr>
                            */}
                        </tbody>
                    </table>
                </div>
                
                <div className='col-3'>
                    
                </div>
            </div>
        </div>
    )
}

export default PrintOPDSlip