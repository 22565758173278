import React,{useEffect, useState} from 'react'
import withDoctorLayout from '../../withDoctorLayout'
import DateSelect from '../../../../components/AppInput/DateSelect'
import doctorDashboardServices from '../doctorDashboardServices';
import { useAuth } from '../../../../context/AuthContext';
import moment from 'moment';

function DoctorDashboardPage() {
    const [filter,setFilter] = useState(null);
    const [dashboardData,setDashboardData] = useState(null);

    const getDashboardData = () =>{
        if(filter!==null){
            doctorDashboardServices.getDashboardData(filter).then(res=>{
                setDashboardData(res.data);
            }).catch(error=>{
    
            })
        }
    }

    useEffect(()=>{
        getDashboardData()
    },[filter])
    
    const {authUser} = useAuth();
    return (
        <>
            <div className='row'>
                <div className='col-md-6'>
                    <p>Hello <span className='text-success fw-bold'>{authUser.firstName} {authUser.lastName}!</span></p>
                </div>
                <div className='col-md-6'>
                    <span className='float-end'><DateSelect filter={filter} setFilter={setFilter} /></span>
                </div>
            </div>
            {dashboardData &&
                <div className='row mt-3'>
                    <div class="col-md-4 col-sm-6 col-xs-6">
                        <div class="card bg-primary text-white shadow p-1 m-2">
                            <p>Waiting</p>
                            <span class="text-center">
                                <h1>{dashboardData.counts.waitingCount}</h1>
                            </span>
                        </div>
                    </div>

                    <div class="col-md-4 col-sm-6 col-xs-6">
                        <div class="card bg-warning shadow p-1  m-2">
                            <p>My OPD</p>
                            <span class="text-center">
                                <h1>{dashboardData.counts.opdCount}</h1>
                            </span>
                        </div>
                    </div>

                    
                    <div class="col-md-4 col-sm-6 col-xs-6">
                        <div class="card bg-danger text-white shadow p-1 m-2">
                            <p>Closed</p>
                            <span class="text-center">
                                <h1>{dashboardData.counts.closedCount}</h1>
                            </span>
                        </div>
                    </div>

                    
                    <div class="col-md-4 col-sm-6 col-xs-6">
                        <div class="card bg-success text-white shadow p-1 m-2">
                            <p>My Patients</p>
                            <span class="text-center">
                                <h1>{dashboardData.counts.patientCount}</h1>
                            </span>
                        </div>
                    </div>

                    <div class="col-md-4 col-sm-6 col-xs-6">
                        <div class="card bg-info shadow p-1  m-2">
                            <p>Billed</p>
                            <span class="text-center">
                                <h1>{dashboardData.counts.billedCount}</h1>
                            </span>
                        </div>
                    </div>

                    <div class="col-md-4 col-sm-6 col-xs-6">
                        <div class="card bg-dark text-white shadow p-1  m-2">
                            <p>Total Collection</p>
                            <span class="text-center">
                                <h1>{dashboardData.counts.collectionTotal}</h1>
                            </span>
                        </div>
                    </div>
                
                    <div className='col-md-12 mt-3'>
                        <h3>Scheduled Appointments ({dashboardData.counts.scheduledCount})</h3>
                    </div>

                    <div className='col-md-12'>
                        <div className='row'>
                            {dashboardData.scheduledAppointments.map(token=>
                                <div className='col-md-3'>
                                    <div className='card'>
                                        <div className='card-body mb-2'>
                                            <h5>{moment(token.checkinDate).format("ddd, Do-MMM")}</h5>
                                            <h5>{token.firstName} {token.lastName}</h5>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='col-md-12 mt-3'>
                        <h3>Recent Patients</h3>
                    </div>

                    <div className='col-md-12'>
                        <div className='row'>
                            {dashboardData.recentVisitors.map(token=>
                                <div className='col-md-3'>
                                    <div className='card mb-2'>
                                        <div className='card-body'>
                                            <h5>{token.firstName} {token.lastName}</h5>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    
                    <div className='col-md-12'>
                        {/*JSON.stringify(dashboardData) */}
                    </div>
                </div>
            }
        </>
    )
}

export default withDoctorLayout(DoctorDashboardPage)