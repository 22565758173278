import api from "../../api/api";

const getAccountBalance = (patientId) =>{
    return new Promise((resolve, reject)=>{
        api.get("/accounts/patient/account-balance/"+patientId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getTransactionList = (patientId) =>{
    return new Promise((resolve, reject)=>{
        api.get("/accounts/patient/transactions/"+patientId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const receivePayment = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/accounts/payment/receive-payment",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const cancelTransaction = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/accounts/payment/cancel-transaction",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const patientAccountServices = {
    getAccountBalance,
    getTransactionList,
    receivePayment,
    cancelTransaction,
}
export default patientAccountServices;