import React, { useState,useEffect, useRef } from 'react'
import DateFilterComponent from '../components/DateFilterComponent'
import moment from 'moment';
import useSystemMasterList from '../../../../hooks/useSystemMasterList';
import { useReactToPrint } from 'react-to-print';
import accountSectionServices from '../../sections/accountSectionServices';
import repportSectionServices from './repportSectionServices';

function MyCollectionReportSection() {

    const [filter,setFilter] = useState(null)
    const [transactionList,setTransactionList] = useState(null);
    const [staffUserDetails,setStaffUserDetails] = useState(null);


    const [masters,getMasterLabel] = useSystemMasterList(['payment-method']);

    const getTransactionList = () =>{
        if(filter){
            repportSectionServices.getMyCollectionReport(filter).then(res=>{
                setTransactionList(res.data.collectionReport);
                setStaffUserDetails(res.data.userDetails);
                console.log(res.data)
            })
        }
    }

    useEffect(()=>{
        getTransactionList();
    },[filter])

    const doTotal = (paymentMethod) =>{
        let total = 0
        if(transactionList){
            transactionList.forEach(transaction => {
                if(transaction.paymentMethod == paymentMethod){
                    total+= transaction.totalAmount;
                }
            });
        }
        return total
    }

    const doGrandTotal = () =>{
        let total = 0
        if(transactionList){
            transactionList.forEach(transaction => {
                total+= transaction.totalAmount;
            });
        }
        return total
    }



    const printRef= useRef()
    const handlePrint = useReactToPrint({
        content: ()=>printRef.current,
        //pageStyle:"@page { size: 6in 3in; margin: 0; padding:0 }, @media print { @page {  size: 6in 3in margin: 0mm !important;  body,html{padding:0px;margin:0px;} } }"
    })


    return (
        <div>
            <div className="row">
                <div className="col-md-9">
                    <DateFilterComponent setFilter={setFilter} />
                </div>
                <div className='col-md-3'>
                    {transactionList && transactionList.length > 0 &&
                        <button className='btn btn-primary float-end mt-3 btn-lg' onClick={handlePrint} >Print</button>
                    }
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <table className='table table-striped table-bordered border-dark' ref={printRef}>
                        <thead>
                            {staffUserDetails &&
                            <tr>
                                <th colSpan={2}>Collected By</th>
                                <td colSpan={2}>{staffUserDetails.firstName} {staffUserDetails.lastName}</td>
                                <td colSpan>
                                    <strong>{moment(filter.startDate).format("ll")}</strong> to <strong>{ moment(filter.endDate).format("ll")}</strong> 
                                    <br/>
                                    Report Time : <strong>{moment().format('LTS')}</strong>
                                </td>
                            </tr>
                            }
                            <tr>
                                <th>Trx #</th>
                                <th>Patient</th>
                                <th>Payment Method</th>
                                <th>Date</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactionList && transactionList.map(transaction=>
                                <tr key={transaction.id}>
                                    <td>{transaction.id}</td>
                                    <td>{transaction.firstName} {transaction.lastName}</td>
                                    <td>
                                        <span className={transaction.paymentMethod == 1 ? 'badge bg-primary' : "badge bg-success"}>
                                            {masters && getMasterLabel("payment-method", transaction.paymentMethod)}
                                        </span>
                                        {transaction.transactionNo !== null &&
                                        <div>
                                            <span className="fw-bold">T No : </span>{transaction.transactionNo}
                                        </div>
                                        }
                                    </td>
                                    <td>{ moment(transaction.created_at).format("lll")}</td>
                                    <td>{transaction.totalAmount}</td>
                                </tr>
                            )}
                        </tbody>
                        <tfoot>
                            {masters && masters['payment-method'].map(paymentMethod=>
                                paymentMethod.value < 6 &&
                                <tr key={paymentMethod.value} className='table-info' >
                                    <td colSpan={4}> <span className='float-end'> Total {paymentMethod.label}</span></td>
                                    <td>{doTotal(paymentMethod.value)}</td>
                                </tr>
                            )}
                            <tr className='table-dark'>
                                <td colSpan={4}> <span className='float-end'> Grand Total</span></td>
                                <td>{doGrandTotal()}</td>
                            </tr>
                            <tr>
                                <th colSpan={3} >Received By</th>
                                <th colSpan={2} >
                                    <span className=''><br/>Signature</span>
                                    <span className=''><br/>Date : </span>
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default MyCollectionReportSection