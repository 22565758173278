
import React,{useEffect,useState} from 'react'

import { NavLink } from 'react-router-dom'
import AppLayout from '../../layout/components/AppLayout'

const withReceptionLayout = (OriginalComponent) =>(props)  =>{
    return (
        <AppLayout>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-3'>
                        <div className='list-group rounded-0'>
                            <NavLink className="list-group-item" aria-current="page" to={"/reception/dashboard"}>Dashboard</NavLink>
                            <NavLink className="list-group-item" aria-current="page" to={"/reception/checkin"}>Checkin</NavLink>
                            <NavLink className="list-group-item" aria-current="page" to={"/reception/schedule-checkin"}>Schedule Checkin</NavLink>
                            <NavLink className="list-group-item" aria-current="page" to={"/reception/token-payment"}>Token Payment</NavLink>
                            <NavLink className="list-group-item" aria-current="page" to={"/reception/payment-services"}>Payment and Services</NavLink>
                            <NavLink className="list-group-item" aria-current="page" to={"/reception/collection-report"}>My Collection Report</NavLink>

                        </div>
                    </div>
                    <div className='col-md-9'>
                        <OriginalComponent {...props} />
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default withReceptionLayout