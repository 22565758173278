
import React,{useEffect,useState} from 'react'

import { NavLink } from 'react-router-dom'
import AppLayout from '../../layout/components/AppLayout'

const withDoctorLayout = (OriginalComponent) =>(props)  =>{
    return (
        <AppLayout>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-3'>
                        <div className='list-group rounded-0'>
                            <NavLink className="list-group-item" aria-current="page" to={"/doctor/dashboard"}>Dashboard</NavLink>
                            <NavLink className="list-group-item" aria-current="page" to={"/doctor/appointments/checkin"}>Checkin Patients</NavLink>
                            <NavLink className="list-group-item" aria-current="page" to={"/doctor/collection-report"}>My Collection Report</NavLink>
                        </div>
                    </div>
                    <div className='col-md-9'>
                        <OriginalComponent {...props} />
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default withDoctorLayout