import React, { useEffect, useState } from 'react'
import AppLayout from '../../../layout/components/AppLayout'
import patientServices from '../patientServices'
import { Link } from 'react-router-dom'

function PatientListPage() {

    const [patientList,setPatientList] = useState(null)
    const getPatientList = () =>{

        patientServices.getList().then(res=>{
            setPatientList(res.data);
        })
    }
    useEffect(()=>{
        getPatientList()
    },[])

    return (
        <AppLayout>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        {patientList &&
                            <div className="table-responsive">
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th>Patient ID</th>
                                            <th>Patient Name</th>
                                            <th>Phone</th>
                                            <th>DOB</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {patientList.map(patient=>
                                            <tr key={patient.id}>
                                                <td>{patient.id}</td>
                                                <td><strong> {patient.firstName} {patient.lastName}</strong> care of <strong>{patient.careOf}</strong></td>
                                                <td>{patient.phone}</td>
                                                <td>{patient.dob}</td>
                                                <td>
                                                    <Link className='btn btn-primary btn-sm me-2' to={"/patient/details/"+patient.id}>Details</Link>
                                                    <Link className='btn btn-primary btn-sm me-2' to={"/patient/accounts/"+patient.id}>Accounts</Link>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                
                            </div>
                        }
                        {!patientList && 
                            <div className='text-center'>
                                <h3>No Record Found</h3>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default PatientListPage