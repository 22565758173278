import React from 'react'
import withReceptionLayout from '../../withReceptionLayout'

function ReceptionDashboardPage() {
    return (
        <div className='card'>
            <div className='card-header'>Reception DashBoard page</div>
            <div className='card-body'></div>
        </div>
    )
}

export default withReceptionLayout(ReceptionDashboardPage)