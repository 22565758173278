import React, {useEffect, useState} from 'react'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import { useNavigate } from 'react-router';
import authServices from '../authServices';
import { useAuth } from '../../../context/AuthContext';
import { useAlert } from '../../../context/AlertContext';

function LoginPage() {

    const [error,setError] = useState(false);
    const [errorMsg,setErrorMsg] = useState("");
    const {setAuthUser,setIsLoggedIn} = useAuth();

    const {AppAlert} = useAlert();

    const initialValues = {
        username :'',
        password:'',
    }

    const validationRule = Yup.object({
        username: Yup.string().required("Required"),
        password: Yup.string().required("Required"),
    });

    const submitLoginForm = (values, onSubmitProps) => {
        authServices.attemptLogin(values).then( (res) => {
          setAuthUser(res.data);
          setIsLoggedIn(true);
          navigate("/")
        }).catch( error =>{
          setError(true);
          setErrorMsg(error.message);
          AppAlert(error.message,"error")
          //console.log(error);
        })
    }

    const navigate = useNavigate();
    useEffect(()=>{
        if(localStorage.portalUrl === null || localStorage.portalUrl === undefined){
            navigate("/auth/select-portal")
        }
    },[])

    return (
    <div className="container">
        <div className="row mt-5">
            <div className="col-md-8 text-center">
                <img src='/images/g-hospital-logo.gif' style={{width:"50%"}} />
            </div>
            <div className="col-md-4 mt-5">
                <div className="card">
                    <div className="card-body">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationRule}
                        onSubmit={submitLoginForm}
                        enableReinitialize validateOnMount
                    >
                        {
                            (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                {error && 
                                    <div className="col-md-12">
                                    <div className="alert alert-danger">
                                        <strong>!</strong> {errorMsg}
                                    </div>
                                    </div>
                                }

                                <FormikControl control="input" formik={formik} type="text"
                                    label="Username" name="username"
                                    className="col-sm-12 col-xs-12 mt-4"
                                />

                                <FormikControl control="input" formik={formik} type="password"
                                    label="Password" name="password"
                                    className="col-sm-12 col-xs-12 mt-4"
                                />

                                {/* <Link to="/auth/register" className="btn btn-register">Register</Link> */ }
                                <button type="submit" className="btn btn-primary mt-4 float-end btn-lg">Submit</button>
                                </Form>
                            )
                            }
                        }
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default LoginPage