import api from "../../../api/api";

const getQueueList = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/appointment/checkin/queue").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getServiceListByQueueId = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("/appointment/checkin/service/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const doCheckin = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/appointment/checkin/do-checkin",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getCustomerTokenList = (patientId) =>{
    return new Promise((resolve, reject)=>{
        api.get("/appointment/checkin/patient-token/"+patientId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const getScheduleQueueList = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/appointment/checkin/schedule-queue").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getAvailableSlots = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/appointment/checkin/available-slots",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const doScheduleCheckin = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/appointment/checkin/do-schedule-checkin",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getPrintSetting = (printType) =>{
    return new Promise((resolve, reject)=>{
        api.get("/business/print-setting/"+printType).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}




const receptionCheckinServices = {
    getQueueList,
    getServiceListByQueueId,
    doCheckin,
    getCustomerTokenList,
    
    getScheduleQueueList,
    getAvailableSlots,
    doScheduleCheckin,
    

    getPrintSetting,
}
export default receptionCheckinServices;