import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { useState, useEffect } from 'react'
import Calendar from 'react-calendar';
import moment from 'moment';
import { useAlert } from '../../../../context/AlertContext';
import doctorCheckinServices from '../doctorCheckinServices';

function DoctorScheduleNextAppointment({visitor}) {

    const {AppAlert} = useAlert()

    const [dialogStatus,setDialogStatus] =  useState(false);
    const [selectedDate,setSelectedDate] = useState(null);
    const [availableSlots,setAvailableSlot] = useState(null);

    const handleClose =() =>{
        setDialogStatus(false);
    }


    const getAvailableSlots = () =>{
        if(selectedDate){
            setAvailableSlot(null);
            let payload = {
                date: moment(selectedDate).format("YYYY-MM-DD"),
                queueId:visitor.queueId,
            }
            doctorCheckinServices.getAvailableSlots(payload).then(res=>{
                setAvailableSlot(res.data.count);
            }).catch(error=>{
                AppAlert(error.msg,"error")
            })
        }
    }

    useEffect(()=>{
        getAvailableSlots();
    },[selectedDate])

    const doScheduleCheckin = () =>{
        let payload = {
            queueId:visitor.queueId,
            serviceId:visitor.serviceId,
            patientId:visitor.patientId,
            selectedDate:moment(selectedDate).format("YYYY-MM-DD"),
        }
        console.log(payload);
        
        doctorCheckinServices.doScheduleCheckin(payload).then(res=>{
            AppAlert(res.msg,"info");
            handleClose();
            if(res.tokenPaymentTime == 1){
                //socket.emit("send_new_token",{message:"new token received"})
            }
            //navigate("/reception/token-payment/"+patientDetails.id)
        }).catch(error=>{

        })
        
    }
    
    return (
        <>
            <button className='btn btn-outline-success' onClick={()=>setDialogStatus(true)} >Schedule Next Appointment</button>
            <Dialog
                    open={dialogStatus}
                    keepMounted
                    onClose={handleClose}
                    fullWidth={true}
                    maxWidth="md"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>Schedule Next Appointment for {visitor.firstName}</DialogTitle>
                    <DialogContent>
                        <div className='row'>
                            <div className='col-md-6'>
                                <Calendar 
                                    onChange={setSelectedDate} 
                                    value={selectedDate}
                                    minDate={moment().add(1,"day").toDate()} 
                                            //maxDate={moment().add(selectedQueue.maxFutureDays,"day").toDate()}   
                                />
                            </div>
                            <div className='col-md-6'>
                                {availableSlots > 0 ?
                                    <>
                                        <h5 className='mt-5'>{availableSlots} Slot Available</h5>
                                        <button className='btn btn-outline-primary' onClick={doScheduleCheckin} > Book  </button>
                                    </>
                                    :
                                    <span className='badge bg-dark'>No Slots Available</span>
                                }
                            </div>
                        </div>
                    </DialogContent>
            </Dialog>
        </>
    )
}

export default DoctorScheduleNextAppointment