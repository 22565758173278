import React, { useState,useEffect } from 'react'

import AppSelect from "../../../components/AppInput/AppSelect"
import { Add, Remove } from '@mui/icons-material'
import moment from 'moment'
import { useAlert } from '../../../context/AlertContext'

import { CircularProgress, Dialog, DialogContent } from '@mui/material'
import useSystemMasterList from '../../../hooks/useSystemMasterList'
import accountSectionServices from './accountSectionServices'

function PaymentServicesForm({patientId,onSuccess}) {

    
    const [masters,getMasterLabel] = useSystemMasterList(['payment-method',"fee-head"]);

    const [dialogStatus,setDialogStatus] = useState(false);
    const [selectedFeeHead,setSelectedFeeHead] = useState(null);
    const [inputFeeStructure,setInputFeeStructure] = useState(null)
    const [inputQty,setInputQty] = useState(1)
    const [inputServiceDate,setInputServiceDate] = useState(moment().format("YYYY-MM-DD"))

    const {AppAlert} = useAlert();
    const [selectedFeesList,setSelectedFeesList] = useState([]);
    const [grandTotal,setGrandTotal] = useState(0);

    const addItem = () => {
        if(!isNaN(inputServiceDate)){
            AppAlert("Invalid Date","error")
        }
        if(selectedFeesList.find(e=> e.feeStructureId == inputFeeStructure.id && e.serviceDate == inputServiceDate )) {       
            AppAlert(`${inputFeeStructure.title} (${inputFeeStructure.feeHeadTitle})  for ${inputServiceDate}, Already Exist in List`,"error")
            return false;
        }

        let data ={
            patientId:patientId,
            feeHead:inputFeeStructure.feeHead,
            feeHeadTitle:inputFeeStructure.feeHeadTitle,
            feeStructureId:inputFeeStructure.id,
            feeTitle:inputFeeStructure.title,
            gst:inputFeeStructure.gst,
            amount:inputFeeStructure.amount,
            qty:inputQty,
            totalAmount:inputFeeStructure.amount*inputQty,
            serviceDate:inputServiceDate,
        }
        setGrandTotal( grandTotal=> grandTotal+data.totalAmount);
        setSelectedFeesList(state=> [data,...state])
    }

    const removeSelectedFees = (i) =>{
        let _selectedFeesList = [...selectedFeesList];
        setGrandTotal( totalAmount=> totalAmount - selectedFeesList[i].totalAmount);
        _selectedFeesList.splice(i,1); 
        setSelectedFeesList(_selectedFeesList);
    }

    const [savingInProgress,setSavingStatus] = useState(false);
    const addServices = () =>{
        setSavingStatus(true)
        accountSectionServices.addServicesPayment(selectedFeesList).then(res=>{
            AppAlert(res.msg)
            if(onSuccess !== undefined){
                onSuccess();
            }
        }).catch(err=>{
            AppAlert(err.msg,"error")
        }).finally(()=>{
            setSavingStatus(false);
            setDialogStatus(false);
        })
    }

    const [feeStructureList,setFeeStructureList] =  useState(null);
    const getFeeStructureList = () =>{
        if(selectedFeeHead){
            accountSectionServices.getFeeList(selectedFeeHead.value).then(res => {
                setFeeStructureList(res.data);
            })
        } 
    }
    useEffect(()=>{
        getFeeStructureList();
    },[selectedFeeHead])

    const calculateAmountExcludingGST = (amount,gst) =>{
        amount = parseFloat(amount);
        gst = parseFloat(gst);
        return ((amount / (100+ gst))*100).toFixed(2);
    }

    return (
        <>
            
            <button type='button'
                className='btn btn-primary' 
                onClick={()=>setDialogStatus(true)}  
            >Add Services</button> 
            
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={()=>{setDialogStatus(false)}}
                fullWidth={true}
                maxWidth="xl"
            >
                <DialogContent>
                    <div className='row'>
                        { !feeStructureList &&
                            <div className='col-md-12'>
                            <h3>Select Fee Type</h3>
                        </div>
                        }
                        <div className='col-md-12'>
                            {masters && masters['fee-head'].map(feeHead=>
                                <span key={feeHead.value}>
                                    {feeHead.value !== 1 &&
                                    <button type='button' 
                                        className={(selectedFeeHead && feeHead.value == selectedFeeHead.value ) ? "btn btn-primary m-2": "btn btn-outline-primary m-2"} 
                                        onClick={()=>setSelectedFeeHead(feeHead)}
                                    >
                                        {feeHead.label}
                                    </button>
                                    }
                                </span>
                            )}
                        </div>
                    </div>
                    {feeStructureList &&
                    <div className='row'>
                        <div className='col-md-12'>
                            <table className='table table-bordered table-striped'>
                                <thead>
                                    <tr>
                                        <td style={{width:"50%"}}>
                                            {feeStructureList &&
                                            <AppSelect
                                                options={[{id:0,title:"Select Fees"},...feeStructureList]}
                                                value={inputFeeStructure}
                                                setValue={setInputFeeStructure}
                                                label="Fee" 
                                                optionLabel = "title"
                                            />
                                            }
                                        </td>
                                        <td style={{width:"10%"}}>
                                            <div className="form-floating">
                                                <input type="number" className="form-control"
                                                    value={inputQty} onChange={(e)=>{setInputQty(e.target.value)}}
                                                />
                                                <label >Qty</label>
                                            </div>
                                        </td>
                                        <td colSpan={5}>
                                            <div className="form-floating">
                                                <input type="date" className="form-control"
                                                    value={inputServiceDate} onChange={(e)=>{setInputServiceDate(e.target.value)}}
                                                />
                                                <label >Service Date : </label>
                                            </div>
                                        </td>
                                        <td>
                                            <button className='btn btn-primary btn-lg mb-2' onClick={addItem} 
                                            disabled={(inputFeeStructure === null || inputFeeStructure.id == 0)}
                                            >
                                                <Add/>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr className='table-dark'>
                                        <th>Service</th>
                                        <th>Service Date</th>
                                        <th>Qty</th>
                                        <th>GST</th>
                                        <th>Charges</th>
                                        <th>Charges + GST</th>
                                        <th>Total</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedFeesList.map((listData,i)=>
                                        <tr key={i}>
                                            <td><span className='fw-bold'>{listData.feeTitle}</span> - {listData.feeHeadTitle}</td>
                                            <td>{listData.serviceDate}</td>
                                            <td>{listData.qty}</td>
                                            <td>{listData.gst > 0 ? listData.gst+" %":"" }</td>
                                            <td>{calculateAmountExcludingGST(listData.amount,listData.gst)}</td>
                                            <td>{listData.amount}</td>
                                            <td>{listData.totalAmount}</td>
                                            <td><button className='btn btn-danger btn-sm' onClick={()=>removeSelectedFees(i)}><Remove/></button></td>
                                        </tr>
                                    )}
                                </tbody>
                                {grandTotal > 0 &&
                                    <tfoot>
                                        {/*<tr colSpan={5}>{JSON.stringify(selectedFeesList)}</tr> */}
                                        <tr>
                                            <th colSpan={6}>Grand Total</th>
                                            <th>{grandTotal}</th>
                                            <th>
                                                {savingInProgress ?
                                                    <CircularProgress/>
                                                    :
                                                    <button onClick={addServices} className='btn btn-primary float-end'>Save</button>
                                                }

                                            </th>
                                        </tr>
                                    </tfoot>
                                }
                            </table>
                        </div>
                    </div>
                    }
                </DialogContent>
            </Dialog>  
        </>
    )
}

export default PaymentServicesForm