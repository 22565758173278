import React, { useEffect, useState } from 'react'
import patientAccountServices from '../patientAccountServices';
import { Delete } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, LinearProgress } from '@mui/material';

function PatientTransectionDetails({patientId,accountBalance,getAccountBalance}) {

    const [transactionList,setTransactionList] = useState(null);
    const getTransactionList = () =>{
        patientAccountServices.getTransactionList(patientId).then(res=>{
            setTransactionList(res.data);
        })
    }
    useEffect(()=>{
        setTransactionList(null);
    },[patientId])
    const calculateAmountExcludingGST = (amount,gst) =>{
        amount = parseFloat(amount);
        gst = parseFloat(gst);
        return ((amount / (100+ gst))*100).toFixed(2);
    }

    
    // ******** Cancel Transaction ****
    const [dialogStatus,setDialogStatus] = useState(false);
    const [remark,setRemark] = useState("");
    const [selectedTransaction,setSelectedTransaction] = useState(null);

    const handleClose = () =>{
        setSelectedTransaction(null)
        setDialogStatus(false);
    }

    const handleCancelTransactionButton = (transaction) =>{
        setSelectedTransaction(transaction)
        setDialogStatus(true);
    }

    const [savingInProgress,setSavingStatus] = useState(false);

    const submitCancelTransaction = () =>{
        let payload ={
            transactionId:selectedTransaction.id,
            remark:remark
        }
        setSavingStatus(true);
        patientAccountServices.cancelTransaction(payload).then(res=>{
            //console.log(res);
            getAccountBalance();
            getTransactionList();
        }).catch(error=>{
            console.log(error);
        }).finally(()=>{
            setSavingStatus(false);
            handleClose();
        })
    }

    const getTransectionColor = (transactionType,transactionStatus) =>{
        if(transactionStatus == 0 ){
            return "table-danger";
        }
        return transactionType==1?"table-success":"";
    }

    return (
        <>
            {!transactionList && 
                <div className='row mt-2'>
                    <div className='col-md-12 text-center'>
                        <button className='btn btn-outline-primary' onClick={getTransactionList}>View Transaction History</button>
                    </div>
                </div>
            }
            {
                transactionList &&
                <div className='row mt-2'>
                    <div className='col-md-12'>
                        <div className="table-responsive">
                            <table className='table'>
                                <thead>
                                    <tr className='table-dark'>
                                        <th>Service</th>
                                        <th>Service Date</th>
                                        <th>Qty</th>
                                        <th>GST</th>
                                        <th>Charges</th>
                                        <th>Charges + GST</th>
                                        <th>Total</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transactionList.map(transaction=>
                                        <tr className={getTransectionColor(transaction.transactionType,transaction.transactionStatus)}
                                            key={transaction.id}
                                            style={{textDecoration:transaction.transactionStatus == 0? "line-through":"none"}}
                                        >
                                            <td><span className='fw-bold'>{transaction.feeStructureTitle}</span> - {transaction.feeHeadTitle} </td>
                                            <td>{transaction.serviceDate}</td>
                                            <td>{transaction.qty}</td>
                                            <td>{transaction.gst > 0 ? transaction.gst+" %":"" }</td>
                                            <td>{calculateAmountExcludingGST(transaction.amount,transaction.gst)}</td>
                                            <td>{transaction.amount}</td>
                                            <td>{transaction.totalAmount}</td>
                                            <td>
                                                { transaction.transactionStatus != 0 &&
                                                <button type='button' className='btn btn-sm btn-danger' onClick={()=>handleCancelTransactionButton(transaction)} >
                                                    <Delete/>
                                                </button>
                                                }
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle>Cancel This Transaction ?</DialogTitle>
                {selectedTransaction &&
                    <DialogContent>
                        <div className='row bg-info p-3'>
                            <div className='col-md-12'>
                                <span className='fw-bold'>{selectedTransaction.feeStructureTitle}</span> - {selectedTransaction.feeHeadTitle}
                            </div>
                            <div className='col-md-4'>
                                <span className='fw-bold'>Transaction Type  :  </span>{selectedTransaction.transactionType==1?"Credit":"Debit"}
                            </div>
                            <div className='col-md-4'>
                                <span className='fw-bold'>Transaction Amount  :  </span>{selectedTransaction.totalAmount}
                            </div>
                            <div className='col-md-4'>
                                <span className='fw-bold'>Service Date  :  </span>{selectedTransaction.serviceDate}
                            </div>
                        </div>

                        <div className='row mt-3'>
                            <div className='col-md-12'>
                                <div className="form-floating">
                                    <textarea className="form-control"
                                        value={remark}
                                        onChange={(e)=>setRemark(e.target.value)}
                                    ></textarea>
                                    <label>Remark</label>
                                    {remark.length < 6 && <p className='text-danger mt-2'>Remark must be atleast 6 character long</p>}
                                </div>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            {!savingInProgress &&
                            <div className='col-md-12'>
                                <button type='button' className='btn btn-outline-danger' onClick={handleClose} >Cancel</button>
                                <button 
                                    type='button'
                                    className='btn btn-danger float-end'
                                    disabled={remark.length < 6}
                                    onClick={submitCancelTransaction}
                                >
                                    Submit
                                </button>
                            </div>
                            }
                            {savingInProgress &&
                                <div className='col-md-12'><LinearProgress/></div>
                            }
                        </div>
                    </DialogContent>
                }
            </Dialog>
        </>
    )
}

export default PatientTransectionDetails