import { ArrowRight } from '@mui/icons-material'
import moment from 'moment'
import React from 'react'

function TokenPatientDetails({visitor}) {
  return (
    <div className='card'>
        <div className='card-body'>
            <div className='row'>
                <div className='col-md-2'>
                    {visitor.profileImage==null?
                        <img src='/images/blank-profile.png' className='v2-image' />
                        :
                        <img src={visitor.profileImage}  className='v2-image'></img>
                    }
                </div>
                <div className='col-md-9'>
                    <table className='table'>
                        <tbody>
                            <tr>
                                <th>Patient Name</th>
                                <td>{visitor.firstName} {visitor.lastName} </td>
                            </tr>
                            <tr>
                                <th>DOB</th>
                                <td>{moment(visitor.dob).format('ll')} <span className='text-primary fw-bold'>Age ({moment().diff(visitor.dob,"years")})</span></td>
                            </tr>
                            <tr>
                                <th>Department</th>
                                <td>{visitor.queueTitle} <ArrowRight/> {visitor.serviceTitle}</td>
                            </tr>
                            {/*
                            <tr>
                                <td colSpan={2}>
                                    <button className='btn btn-outline-primary'>Diagnosis & Treatment</button>
                                    <button className='btn btn-outline-primary ms-2'>Lab Reports</button>
                                    <button className='btn btn-outline-primary ms-2'>Schedule Next Appointment</button>
                                </td>
                            </tr>
                            */}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        {/* JSON.stringify(visitor) */}
    </div>
  )
}

export default TokenPatientDetails