import React,{useState,useEffect} from 'react'


import { Dialog, DialogContent, LinearProgress } from '@mui/material';
import moment from 'moment';
import { useAlert } from '../../../context/AlertContext';
import patientServices from '../patientServices';
import PatientForm from './PatientForm';

function PatientSearchComponent({ patientDetails, setPatientDetails,refresh,setRefresh}) {
    const {AppAlert} = useAlert()
    const [loading,setLoadingStatus] = useState(null);

    const [inputPatientId,setInputPatientId] = useState("");
    const getPatientDetails = () =>{
        setPatientDetails(null);
        setLoadingStatus(true);
        patientServices.getById(inputPatientId).then(res=>{
            if(res.data == null ){
                AppAlert("Invalid Patient Id","error")
            }
            setPatientDetails(res.data);
        }).catch(error=>{
            AppAlert(error.msg,"error")
        }).finally(()=>{
            setLoadingStatus(false);
        })
    }
    const handlePatintIdKeydown = (e) =>{
        if (e.key === 'Enter') {
            console.log('do validate');
            getPatientDetails();
        }
    }

    // Refresh Details
    const refreshPatientDetails = () =>{
        if(refresh == true && inputPatientId!==""){
            getPatientDetails();
            setRefresh(false);
        }
    }
    useEffect(()=>{
        refreshPatientDetails();
    },[refresh])


    // Search By Phone Number 
    const [dialogStatus,setDialogStatus]     =  useState(false);
    const [inputPhoneNo,setInputPhoneNo] = useState("");
    const [searchList,setSearchList] = useState(null);
    const searchByPhoneNo = () =>{
        patientServices.searchByPhone(inputPhoneNo).then(res=>{
            setSearchList(res.data);
        }).catch(error=>{
        })
    }
    const searchSelect = (id) =>{
        setInputPatientId(id); 
        setRefresh(true);
        setDialogStatus(false);
    }
    const handlePatintPhoneNoKeydown = (e) =>{
        if (e.key === 'Enter') {
            console.log('do validate');
            searchByPhoneNo();
        }
    }

    // Add Patient Form 
    const addPatientSuccess = (res) =>{
        if(res.id !== undefined){ //new Patient Created
            setInputPatientId(res.id);
            setRefresh(true);
        } else {
            getPatientDetails();
        } 
    }

  return (
    <div className='row'>
        {!loading && 
            <>
                <div className='col-md-6'>
                    <div className="form-floating">
                        <input type="text" className="form-control"
                            value={inputPatientId} onChange={(e)=>{setInputPatientId(e.target.value)}} onKeyDown={handlePatintIdKeydown}
                        />
                        <label >Search Patient Id : </label>
                    </div>
                </div>
                <div className='col-md-6'>
                    <span className='m-2'>OR</span>
                    <button type="button" className='btn btn-link me-2' onClick={()=>setDialogStatus(true)}>Search By Phone</button>
                    <span className='m-2'>OR</span>
                    <PatientForm onSuccess={addPatientSuccess} /> 
                </div>

                <Dialog
                    open={dialogStatus}
                    keepMounted
                    onClose={()=>{setDialogStatus(false)}}
                    fullWidth={true}
                    maxWidth="md"
                >
                    <DialogContent>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className="form-floating">
                                    <input type="text" className="form-control"
                                        value={inputPhoneNo} onChange={(e)=>{setInputPhoneNo(e.target.value)}}
                                        onKeyDown={handlePatintPhoneNoKeydown}
                                    />
                                    <label >Enter PhoneNo </label>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <button className='btn btn-primary' onClick={searchByPhoneNo} >Search</button>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-12'>
                                {searchList && searchList.length > 0 &&
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>S/O, D/O, W/O</th>
                                                <th>Patient ID</th>
                                                <th>User Since</th>
                                                <th>Last Upadate</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {searchList.map(patient =>
                                                <tr onClick={()=>searchSelect(patient.id)} >
                                                    <td>{patient.firstName} {patient.lastName}</td>
                                                    <td>{patient.careOf}</td>
                                                    <td>P-{patient.id}</td>
                                                    <td>{ moment(patient.created_at).format("ll") }</td>
                                                    <td>{ moment(patient.updated_at).format("ll") }</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                }
                                {searchList && searchList.length == 0 &&
                                    <div className="text-center text-danger mt-2 fw-bold">No Record Found</div>
                                }
                            </div>
                        </div>
                    
                    </DialogContent>
                </Dialog>
            </>
        }
        {loading && 
            <div className='col-md-12'>
                <LinearProgress/>
            </div>
        }
        {!loading && patientDetails && 
            <div className='col-md-12'>
                <table className='table table-bordered mt-2'>
                    <thead>
                        <tr>
                            <th className='table-info'>Patient Name</th>
                            <td><strong> <span className='text-success'>{patientDetails.firstName} {patientDetails.lastName}</span> (Age:{moment().diff(patientDetails.dob, 'years')}) </strong>care of <strong>  {patientDetails.careOf}</strong> </td>
                            <th className='table-info'>Phone</th>
                            <th>{patientDetails.phone}</th>
                        </tr>
                    </thead>
                </table>
            </div>
        }
    </div>
  )
}

export default PatientSearchComponent