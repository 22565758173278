import React, { useEffect, useState } from 'react'
import withDoctorLayout from '../../withDoctorLayout'
import doctorCheckinServices from '../doctorCheckinServices';
import { ArrowRight, Campaign, Check, DirectionsWalk, PanTool, VisibilityOff } from '@mui/icons-material';
import TokenPatientDetails from '../components/TokenPatientDetails';
import TransferToken from '../components/TransferToken';
import { useAlert } from '../../../../context/AlertContext';
import { useSocket } from '../../../../context/SocketContext';
import ReceiveTokenPayment from '../../../accounts/sections/ReceiveTokenPayment';
import useSystemMasterList from '../../../../hooks/useSystemMasterList';
import DoctorScheduleNextAppointment from '../components/DoctorScheduleNextAppointment';

function DoctorCheckinAppointmentPage() {
    const {AppAlert}  = useAlert()
    const [pendingList,setPendingList] = useState(null);
    const [visitor,setVisitor] = useState(null);
    const [loading,setLoadingStatus] = useState(false);
    const [masters] =  useSystemMasterList(['payment-method']);

    const {socket} = useSocket();

    const getPendingList = () => {
        doctorCheckinServices.getCheckinPendingList().then(res=>{
            setPendingList(null);
            if(res.data.length > 0 ){
                setPendingList(res.data)
            }
        })
    }
    useEffect(()=>{
        getPendingList();
    },[]);

    const [holdTokenExist,setHoldTokenExist]  =   useState(false)
    useEffect(()=>{
        if(pendingList){
            let found = pendingList.find(token=>token.status == 7)
            if(found === undefined){
                setHoldTokenExist(false);
            } else {
                setHoldTokenExist(true);
            }
        }
    },[pendingList])


    
    const updateCheckinStatus  = (status) =>{
        let data = {
            id:visitor.id,
            status:status
        }
        doctorCheckinServices.updateCheckinStatus(data).then(res=>{
            refreshList();
            socket.emit("send_close_token",{message:"Token Closed","data": data})
        }).catch(error=>{
            
        })
    }

    const refreshList = () =>{
        setVisitor(null)
        getPendingList();
    }


    const callPatient =() =>{
        setLoadingStatus(true);
        doctorCheckinServices.callCheckinVisitor().then(res=>{
            setVisitor(res.data);
            socket.emit("send_announcement",{message:"Hello App1","token": res.data})
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }

    const [recallButtonStatus,setRecallButtonStatus] = useState(true);
    const recallVisitor = (visitor) =>{
        setRecallButtonStatus(false);
        socket.emit("send_announcement",{message:"Hello App1","token": visitor})
        setTimeout(()=>{
            setRecallButtonStatus(true);
        },15000)
    }

    const callOnHoldVisitor = (tokenId) =>{
        setLoadingStatus(true);
        doctorCheckinServices.callOnHoldVisitor({tokenId:tokenId}).then(res=>{
            setVisitor(res.data);
            socket.emit("send_announcement",{message:"Hello App1","token": res.data})
        }).catch(error=>{
        })
    }



    // socket events
    useEffect(()=>{
        socket.on("receive_announcement",(data)=>{
            //console.log("visitor invited Received",data);
            getPendingList();
        })
        return () => socket.off('receive_announcement');
    },[socket])
    useEffect(()=>{
        //console.log("I called again ");
        socket.on("receive_new_token",(data)=>{
            //console.log("new token Received",data);
            getPendingList();
            
        })
        return () => socket.off('receive_new_token');
    },[socket])
    useEffect(()=>{
        //console.log("I called again ");
        socket.on("receive_close_token",(data)=>{
            //console.log("close received",data);
            getPendingList();
        })
        return () => socket.off('receive_close_token');
    },[socket])



    const reloadCheckinVisitorDetails =() =>{
        setLoadingStatus(true);
        doctorCheckinServices.callCheckinVisitor().then(res=>{
            setVisitor(res.data);
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }

    return (
        <div className='row'>

            <div className="col-md-8">
                <div className="card">
                    <div className="card-header">Patient</div>
                    <div className="card-body">
                        {!pendingList && !visitor && <h4>No Patient</h4>}
                        {pendingList && !visitor && 
                            <button type="button" className="btn btn-primary btn-lg" onClick={callPatient}><DirectionsWalk/> Call Patient</button>
                        }
                        {visitor && 
                            <>
                                <div className="row mb-2">
                                    <div className="col-6">
                                        <h4> Token # : </h4>
                                    </div>
                                    <div className="col-6">
                                        <span className="bg-primary text-white rounded shadow  p-2" style={{fontSize:26}}>{visitor.tokenPrefix} - {visitor.tokenNo} </span>
                                    </div>
                                </div>
                                <TokenPatientDetails visitor={visitor} />
                                <div className="row">
                                    <div className="col-md-12">
                                        
                                        <button type="button" disabled={!recallButtonStatus} onClick={()=>recallVisitor(visitor)} className="btn btn-warning m-2"><Campaign/>Recall</button>
                                        <button type="button" onClick={()=>updateCheckinStatus(3)} className="btn btn-danger m-2"><VisibilityOff/> Not Apeared</button>
                                        <button type="button" onClick={()=>updateCheckinStatus(7)} className="btn btn-secondary m-2"><PanTool/> Put on Hold</button>
                                        
                                        <TransferToken visitor={visitor} refreshList={refreshList} />
                                        <DoctorScheduleNextAppointment visitor={visitor} />
                                        <span className="float-end mt-5 me-4">
                                        {visitor?.paymentStatus === 1  ? 
                                            <button type="button" onClick={()=>updateCheckinStatus(4)} className="btn btn-success m-2"><Check/> Finish</button>
                                            :
                                            <ReceiveTokenPayment
                                                patientToken={visitor} 
                                                onSuccess={reloadCheckinVisitorDetails}
                                                masters={masters}
                                            />
                                        }
                                        </span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        {/*JSON.stringify(visitor)*/}
                                    </div>
                                </div>
                            </>
                        }
                        {! visitor && holdTokenExist &&
                            <div className='row'>
                                <div className='col-md-12'>
                                        <div className="card mt-2">
                                            <div className="card-header bg-secondary text-white"> <PanTool/> On Hold</div>
                                            <div className="card-body" style={{maxHeight:300, overflowY:"scroll"}}>
                                                {pendingList &&
                                                    pendingList.map(token=>
                                                        token.status === 7 &&
                                                        <div className="mt-2 p-2 shadow rounded" key={token.id}>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <span className="float-start"><h4>{token.tokenPrefix}-{token.tokenNo}</h4></span>
                                                                    <button type="button" 
                                                                        className="float-end btn btn-primary"
                                                                        onClick={()=>callOnHoldVisitor(token.id)}
                                                                    >Recall Visitor</button>
                                                                </div>
                                                            </div>
                                                            <div>{token.queueTitle} <ArrowRight/> {token.serviceTitle}</div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                    </div>
                </div>
            </div>



            <div className='col-md-4'>
                <div className="card">
                    <div className='card-header'>Next In Queue</div>
                    <div className='card-body'>
                        {pendingList && pendingList.map(token=>
                            <div className='border shadow p-2 my-2' key={token.id}>
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <h4>{token.tokenPrefix}-{token.tokenNo}</h4>
                                        <h6>{token.queueTitle} <ArrowRight/> {token.serviceTitle}</h6>
                                    </div>
                                    <div>
                                        
                                        {token.paymentStatus === 0 ?
                                            <>
                                                {token.tokenPaymentTime == 2 ?
                                                    <span className="float-end">
                                                        <h4 className={"badge bg-info me-1"}>Waiting For Payment</h4>   
                                                    </span>
                                                    :
                                                    <span className="float-end"><h4 className="badge bg-danger">Payment Pending</h4></span>
                                                }
                                            </>
                                            :
                                            <>
                                                <span className="float-end"><h4 className="badge bg-success">Payment Done</h4></span>
                                            </>
                                        }
                                        <span className="float-end"><h4 className={token.status == 1 ? "badge bg-primary me-1" : "badge bg-warning text-danger me-1"}>{token.statusTitle}</h4></span>
                                    </div>
                                </div>
                            </div>
                        )}
                        {pendingList && pendingList.length == 0 &&
                            <h6 className='text-success'>No Patient</h6>
                        }
                    </div>
                </div>
                
            </div>
        </div>
        
    )
}

export default withDoctorLayout(DoctorCheckinAppointmentPage)