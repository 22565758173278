import React from 'react'
import moment from 'moment'

import AwsImageInline from '../../../components/InlineEdit/AwsImageInline'
import patientServices from '../patientServices'

function PatientBasicDetails({patientDetails,onSuccess,masters,getMasterLabel}) {
    return (
        <div className='row mt-3'>
            <div className='col-md-3'>
                <AwsImageInline
                    label="Profile Image"
                    name="profileImage"
                    value={patientDetails.profileImage}
                    editId={patientDetails.id}
                    updateInline={patientServices.updateInline}
                    path="patient/profile"
                    fileId={patientDetails.firstName}
                    onSuccess={onSuccess}
                />
            </div>
            <div className='col-md-9'>
                <table className='table'>
                    <tbody>
                        <tr>
                            <th>Name</th>
                            <td>
                                {patientDetails.firstName} {patientDetails.lastName}
                            </td>
                        </tr>
                        <tr>
                            <th>Gender</th>
                            <td>{getMasterLabel("gender",patientDetails.gender)}</td>
                        </tr>
                        <tr>
                            <th>DOB</th>
                            <td>
                                {moment(patientDetails.dob).format("LL")}, <span className='fw-bold'>Age : </span> {moment().diff(patientDetails.dob, 'years') }
                            </td>
                        </tr>
                        <tr>
                            <th>careOf</th>
                            <td>{patientDetails.careOf}</td>
                        </tr>
                        <tr>
                            <th>Phone</th>
                            <td>{patientDetails.phone}</td>
                        </tr>
                        <tr>
                            <th>Email</th>
                            <td>{patientDetails.email}</td>
                        </tr>
                        <tr>
                            <th>ID Proff</th>
                            <td>{getMasterLabel("document-type",patientDetails.documentType)} {patientDetails.documentId}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className='col-md-12'>
                {/*JSON.stringify(patientDetails) */}
            </div>
        </div>
    )
}

export default PatientBasicDetails