import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router-dom'
import withPatientDetailsLayout from '../withPatientDetailsLayout';
import patientAccountServices from '../patientAccountServices';
import PatientAccountBalance from '../components/PatientAccountBalance';


function PatientAccountPage() {

    const {patientId} = useParams();

    const onSuccess = () =>{

    }

    return (    
        <PatientAccountBalance patientId={patientId} info="details" onSuccess={onSuccess}/>
    )
}

export default withPatientDetailsLayout(PatientAccountPage)