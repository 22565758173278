import React,{useEffect, useState} from 'react'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import { useAlert } from '../../../context/AlertContext'
import { Dialog, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip } from '@mui/material';
import { Edit } from '@mui/icons-material';
import moment from 'moment';
import patientServices from '../patientServices';
import useSystemMasterList from '../../../hooks/useSystemMasterList';

function PatientForm({data,onSuccess}) {
    const {AppAlert} = useAlert();
    const [dialogStatus, setDialogStatus] = useState(false);
    const [formValues, setFormValues] = useState(null);
    const [savingInProgress, setSavingStatus] = useState(false);
    const [masters] = useSystemMasterList(['document-type']);

    const [ageInputStatus,setAgeInputStatus] = useState(false);
    const handleAgeInput = (value,formik) =>{
        console.log(formik);
        console.log(value);
        let _dob = moment().subtract(value, 'years').format("YYYY-MM-DD");
        formik.setFieldValue("dob",_dob);
    }
    
    const initialValues = {
        firstName:"",
        lastName:"",
        gender:"",
        careOf:"",
        dob:"",
        email:"",
        phone:"",
        address:"",
        documentType : 1,
        documentId : "",
    }

    const validationRule = Yup.object({
        firstName:Yup.string().required("Required"),
        gender:Yup.string().required("Required"),
        careOf:Yup.string().required("Required"),
        dob:Yup.date().required("Required"),
        phone:Yup.string().required("Required").min(10, 'Phone number must be 10 digit').max(10, 'Phone number must be 10 digit'),
    });

    const onSubmit = (values) =>{
        setSavingStatus(true);
        patientServices.save(values).then(res=>{
            setDialogStatus(false);
            onSuccess(res); 
            AppAlert(res.msg)
        }).catch(error=>{
            AppAlert(error.msg,"error")
        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    const addNew = ()=>{
        setFormValues(initialValues);
        setDialogStatus(true)
    }

    const updateData = () =>{
        let autoFill = {
            id:data.id,
            firstName:data.firstName,
            lastName:data.lastName,
            gender:data.gender,
            careOf:data.careOf,
            dob:data.dob,
            documentType:data.documentType,
            documentId:data.documentId,
            phone:data.phone,
            email:data.email,
            address:data.addres,   
        }
        setFormValues(autoFill);
        setDialogStatus(true)
    }

    return (
        <>
            {!data &&
                
                <Tooltip title="Add New">
                    <button type="button" className="btn btn-primary" onClick={addNew} >Add New Patient</button>
                </Tooltip>
            }
            {data &&
                <Tooltip title="Update">
                    <IconButton onClick={updateData} style={{padding:"0px"}}>
                        <Edit/>
                    </IconButton>
                </Tooltip>
            }
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={()=>{setDialogStatus(false)}}
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle>Patient Form</DialogTitle>
                <DialogContent>
                    <Formik
                            initialValues={formValues || initialValues}
                            validationSchema={validationRule}
                            onSubmit={onSubmit}
                            enableReinitialize validateOnMount
                        >
                        {
                            (formik) => {
                                //console.log(formik.values);
                                return (
                                    <Form> 
                                        <div className="row ps-2">

                                            <div className="col-md-12 text-center">
                                                <div className="d-flex justify-content-start">
                                                    <span className={formik.errors["gender"] !== undefined && formik.touched["gender"]?"text-danger fw-bold pt-2":"fw-bold pt-2"}>Gender : </span>
                                                    <button type="button" className={formik.values.gender==1 ? "btn btn-primary ms-2" : "btn btn-outline-primary ms-2"} onClick={()=>formik.setFieldValue("gender",1)} >Male</button>
                                                    <button type="button" className={formik.values.gender==2 ? "btn btn-primary ms-2" : "btn btn-outline-primary ms-2"} onClick={()=>formik.setFieldValue("gender",2)} >Female</button>
                                                </div>
                                                <div className="d-flex">
                                                    { formik.errors["gender"] !== undefined && formik.touched["gender"] && 
                                                        <span className="text-danger">{formik.errors["gender"]}</span>
                                                    }
                                                </div>
                                            </div>

                                            <FormikControl control="input" formik={formik} type="text" 
                                                label="First Name" name="firstName"
                                                className="col-md-4 pe-1"
                                            />
                                            <FormikControl control="input" formik={formik} type="text" 
                                                label="Last Name" name="lastName"
                                                className="col-md-4 pe-1"
                                            />
                                            <FormikControl control="input" formik={formik} type="text" 
                                                label="S/O, W/O, D/O" name="careOf"
                                                className="col-md-4 pe-1"
                                            />

                                            <div className='mt-4'></div>

                                            
                                            <FormikControl control="input" formik={formik} type="date" 
                                                label="Date Of Birth" name="dob"
                                                className="col-md-6 pe-1"
                                            />

                                            <div className='col-md-6 pt-3'>
                                                <span className='fw-bold'>Age : </span>
                                                <span className='fw-bold'>
                                                {
                                                    (formik.values.dob === "" || ageInputStatus == true) ? "" : moment().diff(formik.values.dob, 'years')
                                                }
                                                    
                                                    {ageInputStatus == false ?
                                                        <button onClick={()=>setAgeInputStatus(true)} className='ms-2 btn btn-primary btn-sm'>Enter Age Manually</button>
                                                        :
                                                        <input type='number' className='form-controll' onChange={(e)=>handleAgeInput(e.target.value,formik)} />
                                                    }
                                                    
                                                </span>
                                            </div>

                                            
                                            <div className='mt-4'></div>
                                            <FormikControl control="input" formik={formik} type="number" 
                                                label="Phone" name="phone"
                                                className="col-md-3 pe-1"
                                            />
                                            <FormikControl control="input" formik={formik} type="text" 
                                                label="Email" name="email"
                                                className="col-md-3 pe-1"
                                            />
                                            <FormikControl control="input" formik={formik} type="text" 
                                                label="Address" name="address"
                                                className="col-md-6 pe-1"
                                            />

                                            {masters &&
                                                <FormikControl control="autoselect" formik={formik} 
                                                    label="Document Type" name="documentType"  
                                                    options={masters['document-type']} multiple={false} 
                                                    className="col-md-6 pe-1 mt-5"
                                                />
                                            }
                                            <FormikControl control="input" formik={formik} type="text" 
                                                label="Document ID" name="documentId"
                                                className="col-md-6 pe-1 mt-5"
                                            />

                                        </div>
                                        <div className="row mt-4">
                                            <div className='col-md-12'>
                                                {savingInProgress ? 
                                                    <LinearProgress />
                                                    :
                                                    <>
                                                        <button className="btn btn-outline-primary float-start" type="button" 
                                                            onClick={()=>{setDialogStatus(false)}}
                                                        >Cancel </button>
                                                        <button className="btn btn-primary float-end" type="submit">Save Patient </button>
                                                    </>
                                                    
                                                }
                                            </div>
                                        </div>
                                    </Form>
                                )
                            }
                        }
                    </Formik>
                </DialogContent>
            </Dialog> 
        </>  
    )
}

export default PatientForm