
import React from 'react'

import { NavLink } from 'react-router-dom'
import AppLayout from '../../layout/components/AppLayout'

const withAccountsLayout = (OriginalComponent) =>(props)  =>{
    return (
        <AppLayout>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-3'>
                        <div className='list-group rounded-0'>
                            <NavLink className="list-group-item" aria-current="page" to={"/accounts/dashboard"}>Dashboard</NavLink>
                            <NavLink className="list-group-item" aria-current="page" to={"/accounts/fee-structure"}>Fee Details</NavLink>
                            <NavLink className="list-group-item" aria-current="page" to={"/accounts/reports/staff-collection"}>Staff Collection</NavLink>
                            {/*<NavLink className="list-group-item" aria-current="page" to={"/accounts/reports/department-collection"}>Department Transections</NavLink> */}
                            <NavLink className="list-group-item" aria-current="page" to={"/accounts/reports/transactions"}>All Transactions</NavLink>
                            <NavLink className="list-group-item" aria-current="page" to={"/accounts/reports/patient-pendency"}>Pendency Report</NavLink>
                        </div>
                    </div>
                    <div className='col-md-9'>
                        <OriginalComponent {...props} />
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default withAccountsLayout