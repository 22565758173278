import React from 'react'
import withDoctorLayout from '../../withDoctorLayout'
import MyCollectionReportSection from '../../../accounts/reports/sections/MyCollectionReportSection';

function DoctorCollectionReportPage() {
  return (
    <MyCollectionReportSection />
  )
}

export default withDoctorLayout(DoctorCollectionReportPage);