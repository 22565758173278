import React,{useState,useEffect} from 'react'
import withAccountsLayout from '../../withAccountsLayout'
import { useAuth } from '../../../../context/AuthContext'
import DateSelect from '../../../../components/AppInput/DateSelect';
import accountsDashboardServices from '../accountsDashboardServices';
import moment from 'moment';
import { Link } from 'react-router-dom';

function AccountsDashboardPage() {
    const {authUser} = useAuth();
    const [filter,setFilter] = useState(null);
    const [dashboardData,setDashboardData] = useState(null);

    const getDashboardData = () =>{
        if(filter!==null){
            accountsDashboardServices.getDashboardData(filter).then(res=>{
                setDashboardData(res.data);
            }).catch(error=>{
    
            })
        }
    }

    useEffect(()=>{
        getDashboardData()
    },[filter])
    return (
        <>
            <div className='row'>
                <div className='col-md-6'>
                    <p>Hello <span className='text-success fw-bold'>{authUser.firstName} {authUser.lastName}!</span></p>
                </div>
                <div className='col-md-6'>
                    <span className='float-end'><DateSelect filter={filter} setFilter={setFilter} /></span>
                </div>
            </div>
            {dashboardData &&
            <>
                <div className='row mt-3'>
                    <div className='col-md-7'>
                        <div className='row'>
                            <div class="col-md-6 col-sm-6 col-xs-6">
                                <div class="card bg-danger text-white shadow p-1 m-2">
                                    <p>Total Pendency</p>
                                    <span class="text-center">
                                        <h1>{dashboardData.counts.pendencyAmount < 0 ? 
                                                dashboardData.counts.pendencyAmount *  -1 
                                                : 
                                                dashboardData.counts.pendencyAmount 
                                            }</h1>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 col-xs-6">
                                <div class="card border-danger text-danger White shadow p-1 m-2">
                                    <p>Patient Pendency</p>
                                    <span class="text-center">
                                        <h1>{dashboardData.counts.pendencyCount}</h1>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 col-xs-6">
                                <div class="card bg-warning shadow p-1 m-2">
                                    <p>Billed</p>
                                    <span class="text-center">
                                        <h1>{dashboardData.counts.billed}</h1>
                                    </span>
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-6 col-xs-6">
                                <div class="card bg-success text-white shadow p-1 m-2">
                                    <p>Payment Received</p>
                                    <span class="text-center">
                                        <h1>{dashboardData.counts.received}</h1>
                                    </span>
                                </div>
                            </div>
                            

                            <div class="col-md-6 col-sm-6 col-xs-6">
                                <div class="card bg-info shadow p-1 m-2">
                                    <p>Cash Collection</p>
                                    <span class="text-center">
                                        <h1>{dashboardData.counts.cash}</h1>
                                    </span>
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-6 col-xs-6">
                                <div class="card bg-primary text-white shadow p-1 m-2">
                                    <p>Digital Collection</p>
                                    <span class="text-center">
                                        <h1>{dashboardData.counts.digital}</h1>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>  {/**  Col-8 */}
                    <div className='col-md-5'>
                        <div className='card'>
                            <div className='card-header bg-dark text-white'>Collection Report</div>
                            <div className='card-body'>
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th>Staff Name</th>
                                            <th>Cash</th>
                                            <th>Digital</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dashboardData.collectionByStaff && dashboardData.collectionByStaff.map(staff=>
                                            <tr key={staff.stafId} >
                                                <td>{staff.firstName} {staff.lastName}</td>
                                                <td>{staff.cash}</td>
                                                <td>{staff.digital}</td>
                                                <td>{ staff.cash + staff.digital }</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> {/**  Col-4 */}
                    


                </div>

            
                <div className='row mt-3'>
                    <div class="col-md-4 col-xs-6">
                        
                    </div>
                </div>
                
                <div className='row mt-3'>
                    <div class="col-md-6">
                        <div className='card'>
                            <div className='card-header bg-warning'>Recent Billed Transactions</div>
                            <div className='card-body'>
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th>Patient Name</th>
                                            <th>Service</th>
                                            <th>Date</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dashboardData.recentBilledTransections?.map(service => 
                                            <tr key={service.transectionId}>
                                                <td> <Link to={"/patient/details/"+service.patientId}> {service.firstName} {service.lastName} </Link> </td>
                                                <td>{service.serviceTitle} {service.feeHeadTitle}</td>
                                                <td>{moment(service.created_at).fromNow()}</td>
                                                <td>{service.totalAmount}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div className='card'>
                            <div className='card-header bg-success text-white'>Recent Payments</div>
                            <div className='card-body'>
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th>Patient Name</th>
                                            {/*<th>Service</th> */}
                                            <th>Date</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dashboardData.recentPaymentTransections && dashboardData.recentPaymentTransections.map(service=>
                                            <tr key={service.id} >
                                                <td>{service.firstName} {service.lastName}</td>
                                                {/*<td>{service.serviceTitle} {service.feeHeadTitle}</td> */}
                                                <td>{moment(service.created_at).fromNow()}</td>
                                                
                                                <td>{ service.totalAmount }</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>




            </>}
        </>        
    )
}

export default withAccountsLayout(AccountsDashboardPage)