import React, { useState } from 'react'
import AppLayout from '../../../layout/components/AppLayout'
import { Link } from 'react-router-dom'


function PatientDashBoardPage() {

    

    return (
        <AppLayout>
            <div className='row'>
                <div className='col-md-12 text-center'>
                    <Link to="/patient/list" className='btn btn-primary btn-lg'>Patient List</Link>
                </div>
            </div>
        </AppLayout>
    )
}

export default PatientDashBoardPage