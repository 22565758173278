import React, { useEffect, useState } from 'react'
import withCMSLayout from '../sections/withCMSLayout'
import adsServices from '../adsServices';
import AdsForm from '../components/AdsForm';
import AwsImageInline from '../../../../components/InlineEdit/AwsImageInline';
import { Switch } from '@mui/material';

function ManageAdsPage() {

  const [adsList,setAdsList] = useState(null);
  const getAdsList = () =>{
    adsServices.getList().then(res=>{
      setAdsList(res.data)
    }).catch(error=>{
      console.log(error);
    })
  }
  useEffect(()=>{
    getAdsList()
  },[])

  const handleStatusChange = (e,id) =>{
    let payload = {
      id:id,
      status:e.target.checked ? 1 : 0,
    }
    console.log(payload);
    adsServices.save(payload).then(res=>{
      getAdsList();
    })
  }

  return (
    <>
      <div className='row'>
        <div className='col-md-9'>
          <h3>Ads List</h3>
        </div>
        <div className='col-md-3'>
          <span className='float-end'><AdsForm refreshList={getAdsList} /></span>
        </div>
      </div>
      {adsList &&
        <div className='row'>
          {adsList.map(ads=>
            <div className='col-md-4 mb-3'>
              <div className='card'>
                <div className={ads.status === 1 ? 'card-header' : ' bg-danger text-white card-header'}>
                  <div class="d-flex justify-content-between">
                    <div>{ads.title}</div>
                    <div>
                      <AdsForm refreshList={getAdsList} data={ads} />
                      <Switch 
                        color="success"
                        checked={ads.status === 1}
                        onChange={(e)=>handleStatusChange(e,ads.id)}
                      />
                    </div>
                  </div>
                </div>
                <div className='card-body'>
                  {ads.video == "" ? 
                    <AwsImageInline
                      label="Image"
                      name="image"
                      value={ads.image}
                      editId={ads.id}
                      updateInline={adsServices.updateInline}
                      path="cms/ads"
                      fileId={ads.id}
                      onSuccess={getAdsList}
                    />
                    :
                    <iframe src={"https://www.youtube.com/embed/"+ads.video+"?controls=1&showinfo=0&rel=0&autoplay=0&loop=1&mute=1"} frameborder="0" allowfullscreen></iframe>
                  }
                  
                </div>
                <div className='card-body'>
                  {ads.details}
                </div>
              </div>
            </div>      
          )}
          
        </div>
      }
    </>
  )
}

export default withCMSLayout(ManageAdsPage)