import React, { useEffect, useState } from 'react'
import patientAccountServices from '../patientAccountServices'
import { Dialog, DialogContent, DialogTitle,DialogActions, LinearProgress } from '@mui/material';

import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import useSystemMasterList from '../../../hooks/useSystemMasterList';
import FormikControl from '../../../components/FormikControl/FormikControl';
import moment from 'moment';
import { useAlert } from '../../../context/AlertContext';
import PatientTransectionDetails from './PatientTransectionDetails';
import PaymentServicesForm from '../../accounts/sections/PaymentServicesForm';


function PatientAccountBalance({patientId,onSuccess, info="brif"}) {
    const {AppAlert} = useAlert();
    const [accountBalance,setAccountBalance] = useState("...");
    const getAccountBalance = () => {
        patientAccountServices.getAccountBalance(patientId).then(res=>{
            setAccountBalance(res.data);
        }).catch(error=>{
            AppAlert("Somthing went wrong while fetching Account Balance")
        })
    }

    useEffect(()=>{
        getAccountBalance()
    },[])
 
    // Payment Form 
    const [masters,getMasterLabel] = useSystemMasterList(["payment-method"]);
    const [formValues, setFormValues] = useState(null);
    const [savingInProgress, setSavingStatus] = useState(false);
    const [dialogStatus, setDialogStatus] = useState(false);


    const [paymentMethodList,setPaymentMethodList] = useState(null);
    const preparePaymentMethodList = ()=>{
        if(masters && masters['payment-method']){
            let _paymentMethodList = masters['payment-method'];
            _paymentMethodList.pop();
            setPaymentMethodList(_paymentMethodList)
        }
    }

    useEffect(()=>{
        preparePaymentMethodList()
    },[masters])

    const initialValues = {
        patientId:patientId,
        amount:Math.abs(accountBalance),
        paymentMethod:"",
        serviceDate:moment().format("YYYY-MM-DD"),
        transactionNo:"",
    }

    const validationRule = Yup.object({
        amount:Yup.string().required("Required"),
        paymentMethod:Yup.string().required("Required"),
        serviceDate:Yup.string().required("Required"),
    });

    const onSubmit = (values) => {
        setSavingStatus(true);
        patientAccountServices.receivePayment(values).then(res=>{
            console.log(res);
            AppAlert(res.msg);
            setDialogStatus(false);
            if(onSuccess !== undefined){
                onSuccess();
            }
        }).catch(error=>{
            AppAlert("Somthing went wrong","error");
            //console.log(error);
        }).finally(()=>{
            setSavingStatus(false);
        })
    }
    
    return (
        <>
            {info === "brif" &&
                <button className={accountBalance < 0 ? "btn btn-danger" : "btn btn-success"} onClick={()=>setDialogStatus(true)} >Balance : {accountBalance}</button>
            }
            {info === "details" &&
                <div className='row'>
                    <div className='col-md-12'>
                        <span className='float-end mx-2'><PaymentServicesForm patientId={patientId} onSuccess={onSuccess} /></span>
                        <button className={accountBalance < 0 ? "btn btn-danger float-end" : "btn btn-success float-end"} onClick={()=>setDialogStatus(true)} >Account Balance : {accountBalance}</button>
                    </div>
                    <div className='col-md-12'>
                        <PatientTransectionDetails patientId={patientId} accountBalance={accountBalance} getAccountBalance={getAccountBalance} />
                    </div>

                </div>
            }
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={()=>{setDialogStatus(false)}}
                fullWidth={true}
                maxWidth="xs"
            >
                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);s
                            return (
                                <Form>
                                    <DialogTitle>{"Payment"}</DialogTitle>
                                    <DialogContent>
                                        <div className="row mt-3">
                                            {paymentMethodList &&  
                                                <FormikControl control="autoselect" formik={formik} 
                                                    label="Payment Method" name="paymentMethod"  
                                                    options={paymentMethodList} multiple={false} 
                                                    className="col-12"
                                                />
                                            }
                                        </div>
                                        <div className='row m-2'>
                                            <FormikControl control="input" formik={formik} type="number" 
                                                label="Amount" name="amount"  
                                                className="col-12 mt-3"
                                            />
                                            <FormikControl control="input" formik={formik} type="text" 
                                                label="Transaction No" name="transactionNo" 
                                                className="col-12 mt-3" 
                                            />
                                            <FormikControl control="input" formik={formik} type="date" 
                                                label="Payment Date" name="serviceDate" 
                                                className="col-12 mt-3" 
                                            />                                                
                                        </div>

                                        {/*
                                        <div className="row">
                                            <div className='col-md-12'>{   JSON.stringify(formik.values) }</div>
                                        </div>
                                        */}

                                        <div className="row mt-3">
                                            <div className='col-md-12'>
                                                {savingInProgress ? 
                                                        <LinearProgress/> 
                                                    :
                                                        <>
                                                            <button className="btn btn-outline-primary" type="button" onClick={()=>setDialogStatus(false)}>Cancel</button>
                                                            <button className="btn btn-primary float-end" type="submit">Receive Payment</button>
                                                        </>
                                                }
                                            </div>
                                        </div>
                                    </DialogContent>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog> 
        </>
    )
}

export default PatientAccountBalance