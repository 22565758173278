import React from 'react'
import { useAuth } from '../../context/AuthContext'
import { Link, NavLink } from 'react-router-dom'
import { AccountCircle, Announcement, CalendarMonthOutlined, Campaign, Chat, Dvr, FollowTheSigns, Home,  Label,  ListAlt,  ManageAccounts, MoreVert, Newspaper, Notifications, PhoneAndroid, Print, Settings, Store, Tablet, TransferWithinAStation, Tv, WorkOutlined} from '@mui/icons-material';

function AdminHeader() {

    const {authUser } = useAuth();

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top shadow mb-3">
            <div className="container-fluid">
                <Link className="me-5" to="/"><img src='/images/g-hospital-logo.gif' style={{height:40}} /><img src='/globalinc.png' style={{height:40}} /></Link> {/* navbar-brand  */}
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="collapsibleNavbar">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0" style={{fontSize:"18px"}}>
                        <li className="nav-item"><NavLink className="nav-link" to="/queue/dashboard">OPD Queue</NavLink></li>
                        <li className="nav-item"><NavLink className="nav-link" to="/reception/dashboard">Reception Desk</NavLink></li>
                        <li className="nav-item"><NavLink className="nav-link" to="/display/dashboard">Display Board</NavLink></li>
                        <li className="nav-item"><NavLink className="nav-link" to="/accounts/dashboard">Accounts</NavLink></li>
                        <li className="nav-item"><NavLink className="nav-link" to="/patient/dashboard">Patient</NavLink></li>
                        <li className="nav-item"><NavLink className="nav-link" to="/doctor/dashboard">Doctor</NavLink></li>
                        <li className="nav-item"><NavLink className="nav-link" to="/cms/ads">CMS</NavLink></li>
                        <li className="nav-item"><NavLink className="nav-link" to="/gatepass/dashboard">Gatepass</NavLink></li>
                        {/*
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">Dropdown</a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Link</a></li>
                                <li><a className="dropdown-item" href="#">Another link</a></li>
                                <li><a className="dropdown-item" href="#">A third link</a></li>
                            </ul>
                        </li>
                        */}
                    </ul>

                        <ul className="navbar-nav">
                            { authUser.role <= 2 && 
                            <li className="nav-item dropdown">
                                <a className="nav-link" href="!#" id="adminControllDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <MoreVert/> </a>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="adminControllDropdown">
                                    <li><NavLink className="dropdown-item" to="/master/list"><Label /> Status Master</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/business/details"><WorkOutlined/> Business Details</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/print/setting"><Print/> Print Setting</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/user/list"><ManageAccounts/> Manage User Accounts</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/user/role"><Settings/> Manage User Role</NavLink></li>
                                </ul>
                            </li>
                            }



                            <li className="nav-item dropdown">
                                <a className="btn btn-danger" style={{borderRadius:"50px"}} href="!#" id="userDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 
                                    {authUser.firstName.charAt(0)}
                                </a>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
                                    {/*<li><a className="dropdown-item" href="/auth/profile">My Profile</a></li> */ }
                                    <li><hr className="dropdown-divider"/></li>
                                    <li><NavLink className="dropdown-item" to="/auth/logout">Logout</NavLink></li>
                                </ul>
                            </li>
                        </ul>
                </div>
            </div>
        </nav>
    )
}

export default AdminHeader