import React from 'react'
import { Route, Routes } from 'react-router'

import AdminDashboardPage from '../app/admin/dashboard/pages/AdminDashboardPage'
import LogoutPage from '../app/auth/pages/LogoutPage'
import SystemMasterListPage from '../app/master/pages/SystemMasterListPage'

import QueueDashboardPage from '../app/admin/queues/pages/QueueDashboardPage'
import QueueConfigPage from '../app/admin/queues/pages/QueueConfigPage'
import BusinessHoursPage from '../app/admin/queues/pages/BusinessHoursPage'
import BusinessBreaksPage from '../app/admin/queues/pages/BusinessBreaksPage'
import BusinessHolidayPage from '../app/admin/queues/pages/BusinessHolidayPage'
import BusinessServicesPage from '../app/admin/queues/pages/BusinessServicesPage'
import BusinessStaffPage from '../app/admin/queues/pages/BusinessStaffPage'
import UserListPage from '../app/admin/user/pages/UserListPage'
import UserDetailsPage from '../app/admin/user/pages/UserDetailsPage'
import UserRoleListPage from '../app/admin/user/pages/UserRoleListPage'
import BusinessDetails from '../app/admin/business/pages/BusinessDetails'

import DisplayDashboardPage from '../app/display/pages/DisplayDashboardPage'


// Accounts
import AccountsDashboardPage from '../app/accounts/dashboard/pages/AccountsDashboardPage'
import FeeStructureListPage from '../app/accounts/feeStructure/pages/FeeStructureListPage'
import StaffCollectionReportPage from '../app/accounts/reports/pages/StaffCollectionReportPage'
import DepartmentCollectionReportpage from '../app/accounts/reports/pages/DepartmentCollectionReportpage'
import AccountsTransactionReportPage from '../app/accounts/reports/pages/AccountsTransactionReportPage'
import PatientPendencyReportPage from '../app/accounts/reports/pages/PatientPendencyReportPage'

// Reception 
import ReceptionDashboardPage from '../app/reception/dashboard/pages/ReceptionDashboardPage'
import ReceptionCheckinPage from '../app/reception/patient/pages/ReceptionCheckinPage'
import TokenPaymentPage from '../app/reception/patient/pages/TokenPaymentPage'
import GatepassDashboardPage from '../app/gatepass/dashboard/pages/GatepassDashboardPage'
import GatepassGeneratePage from '../app/gatepass/visitor/pages/GatepassGeneratePage'
import GatepassDetailsPage from '../app/gatepass/visitor/pages/GatepassDetailsPage'
import GatepassVisitorListPage from '../app/gatepass/visitor/pages/GatepassVisitorListPage'



//Patient 
import PatientDashBoardPage from '../app/patient/pages/PatientDashBoardPage'
import PatientSearchPage from '../app/patient/pages/PatientSearchPage'
import PatientListPage from '../app/patient/pages/PatientListPage'
import PatientDetailsPage from '../app/patient/pages/PatientDetailsPage'
import PatientAccountPage from '../app/patient/pages/PatientAccountPage'
import PaymentAndServicesPage from '../app/reception/patient/pages/PaymentAndServicesPage'

// Display
import DisplayPage from '../app/display/pages/DisplayPage'
import DoctorDashboardPage from '../app/doctor/dashboard/pages/DoctorDashboardPage'

// Doctor
import DoctorCheckinAppointmentPage from '../app/doctor/appointments/pages/DoctorCheckinAppointmentPage'
import ReceptionCollectionReportPage from '../app/reception/reports/pages/ReceptionCollectionReportPage'
import ReceptionScheduleCheckinPage from '../app/reception/patient/pages/ReceptionScheduleCheckinPage'
import DoctorCollectionReportPage from '../app/doctor/reports/pages/DoctorCollectionReportPage'
import PrintSettingPage from '../app/admin/business/pages/PrintSettingPage'
import ManageAdsPage from '../app/admin/cms/pages/ManageAdsPage'
import ManageNewsPage from '../app/admin/cms/pages/ManageNewsPage'
import ManageKioskInfoPage from '../app/admin/cms/pages/ManageKioskInfoPage'
import KioskInfoDetailsPage from '../app/admin/cms/pages/KioskInfoDetailsPage'
import ManageKioskInfoCategoryPage from '../app/admin/cms/pages/ManageKioskInfoCategoryPage'
import KioskInfoCategoryDetailsPage from '../app/admin/cms/pages/KioskInfoCategoryDetailsPage'
import ManageKioskFaqPage from '../app/admin/cms/pages/ManageKioskFaqPage'
import ManageKioskDirectoryPage from '../app/admin/cms/pages/ManageKioskDirectoryPage'




function AppRoutes() {
  return (
    <Routes>
        {/* Admin */}
        <Route path="/" element={<AdminDashboardPage/>}></Route>
        <Route path="/business/details" element={<BusinessDetails />}></Route>
        <Route path="/print/setting" element={<PrintSettingPage />}></Route>
        <Route path="/queue/dashboard" element={<QueueDashboardPage /> }></Route>
        <Route path="/queue/config/:id" element={<QueueConfigPage /> }></Route>
        <Route path="/queue/business-hours/:id" element={<BusinessHoursPage /> }></Route>
        <Route path="/queue/business-breaks/:id" element={<BusinessBreaksPage /> }></Route>
        <Route path="/queue/business-holiday/:id" element={<BusinessHolidayPage /> }></Route>
        <Route path="/queue/services/:id" element={<BusinessServicesPage /> }></Route>
        <Route path="/queue/staff/:id" element={<BusinessStaffPage /> }></Route>

        {/* CMS */}
        <Route path="/cms/ads" element={<ManageAdsPage /> }></Route>
        <Route path="/cms/news" element={<ManageNewsPage /> }></Route>
        <Route path="/cms/kiosk-info" element={<ManageKioskInfoPage /> }></Route>
        <Route path="/cms/kiosk-info/details/:id" element={<KioskInfoDetailsPage /> }></Route>
        <Route path="/cms/kiosk-category" element={<ManageKioskInfoCategoryPage /> }></Route>
        <Route path="/cms/kiosk-category/details/:id" element={<KioskInfoCategoryDetailsPage /> }></Route>
        <Route path="/cms/kiosk-faq" element={<ManageKioskFaqPage /> }></Route>
        <Route path="/cms/kiosk-directory" element={<ManageKioskDirectoryPage /> }></Route>
        
        {/* Users */}
        <Route path="/user/list" element={<UserListPage /> }></Route>
        <Route path="/user/details/:id" element={<UserDetailsPage /> }></Route>
        <Route path="/user/role" element={<UserRoleListPage /> }></Route>

        

        {/* Display */}
        <Route path="/display/dashboard" element={<DisplayDashboardPage /> }></Route>
        <Route path="/display/tv/:displayType" element={<DisplayPage /> }></Route>
        
        {/* Reception */}
        <Route path="/reception/dashboard" element={<ReceptionDashboardPage />}></Route>
        <Route path="/reception/checkin" element={<ReceptionCheckinPage/>}></Route>
        <Route path="/reception/schedule-checkin" element={<ReceptionScheduleCheckinPage />}></Route>
        <Route path="/reception/token-payment" element={<TokenPaymentPage/>}></Route>
        <Route path="/reception/token-payment/:patientId" element={<TokenPaymentPage/>}></Route>
        <Route path="/reception/payment-services" element={<PaymentAndServicesPage/>}></Route>
        <Route path="/reception/collection-report" element={<ReceptionCollectionReportPage />}></Route>
        <Route path="/gatepass/dashboard" element={<GatepassDashboardPage /> }></Route>
        <Route path="/gatepass/generate" element={<GatepassGeneratePage /> }></Route>
        <Route path="/gatepass/details/:id" element={<GatepassDetailsPage /> }></Route>
        <Route path="/gatepass/visitor" element={<GatepassVisitorListPage /> }></Route>

        {/* Accounts */}
        <Route path="/accounts/dashboard" element={<AccountsDashboardPage />}></Route>
        <Route path="/accounts/fee-structure" element={<FeeStructureListPage />}></Route>
        <Route path="/accounts/reports/staff-collection" element={<StaffCollectionReportPage />}></Route>
        <Route path="/accounts/reports/department-collection" element={<DepartmentCollectionReportpage />}></Route>
        <Route path="/accounts/reports/transactions" element={<AccountsTransactionReportPage />}></Route>
        <Route path="/accounts/reports/patient-pendency" element={<PatientPendencyReportPage />}></Route>

        {/* Patient */}
        <Route path="/patient/dashboard" element={<PatientDashBoardPage/>}></Route>
        <Route path="/patient/search" element={<PatientSearchPage/>}></Route>
        <Route path="/patient/list" element={<PatientListPage/>}></Route>
        <Route path="/patient/details/:patientId" element={<PatientDetailsPage/>}></Route>
        <Route path="/patient/accounts/:patientId" element={<PatientAccountPage />}></Route>


        {/* Doctor */}
        <Route path="/doctor/dashboard" element={<DoctorDashboardPage />}></Route>
        <Route path="/doctor/appointments/checkin" element={<DoctorCheckinAppointmentPage/>}></Route>
        <Route path="/doctor/collection-report" element={<DoctorCollectionReportPage/>}></Route>

        {/* System */}
        <Route path="/master/list" element={ <SystemMasterListPage /> } />
        <Route path="/auth/logout" element={<LogoutPage/>}></Route>

    </Routes>
  )
}

export default AppRoutes