
import React,{useEffect,useState} from 'react'

import { NavLink, useParams } from 'react-router-dom'
import AppLayout from '../../layout/components/AppLayout'
import PatientAccountBalance from './components/PatientAccountBalance'


const withPatientDetailsLayout = (OriginalComponent) =>(props)  =>{

    const {patientId} = useParams()

    return (
        <AppLayout>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-3'>
                        <div className='list-group rounded-0'>
                            <NavLink className="list-group-item" aria-current="page" to={"/patient/details/"+patientId}>Basic Details</NavLink>
                            <NavLink className="list-group-item" aria-current="page" to={"/patient/accounts/"+patientId}>Patient Account Details</NavLink>
                        </div>
                    </div>
                    <div className='col-md-9'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <OriginalComponent {...props} />
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default withPatientDetailsLayout