import api from "../../../api/api";


const  serialize = (obj) =>{
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
}

const getDashboardData = (filters) =>{
    return new Promise((resolve, reject)=>{
        api.get("/dashboard/accounts?"+serialize(filters)).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :"res.data.msg"
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}

const accountsDashboardServices ={
    getDashboardData
}

export default accountsDashboardServices;