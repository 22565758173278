import React, { useState } from 'react'
import { AlertProvider } from './ghospital/context/AlertContext';
import { useAuth } from './ghospital/context/AuthContext'

import AdminRoutes from './ghospital/routes/AdminRoutes'
import AuthRoutes from './ghospital/routes/AuthRoutes'
import { SocketProvider } from './ghospital/context/SocketContext';

function App() {
    const {isLoggedIn} = useAuth();
    return (
        <>
        <AlertProvider>
            {isLoggedIn ? 
                <SocketProvider>
                    <AdminRoutes/>
                </SocketProvider>
                :
                <AuthRoutes/>
            }
        </AlertProvider>
        </>
    )
}
export default App