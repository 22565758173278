import React,{useState,useContext, useEffect} from 'react'
import io from 'socket.io-client'
const SocketContext = React.createContext();

export const useSocket = () => {
    return useContext(SocketContext);
}




export const SocketProvider = (props) =>{

    const [appName,setAppName] = useState(null);
    const [socket,setSocket] =  useState(null)

    const getAppNameOld = () =>{
        let _appName =  "local"//process.env.REACT_APP_SOCKET_NAME;
        const api_url = localStorage.getItem("portalUrl");
        let a = document.createElement('a');
        a.href = api_url;
        _appName = a.hostname.replace("-api.ghospital.in","");
        _appName = "gtoken_"+_appName;
        setAppName(_appName);
        let _socket = io.connect("https://tms-notification.gtoken.in?app="+_appName);
        setSocket(_socket);
    }

    const getAppName = () =>{
        let _appName = "gh-"+localStorage.getItem("portalId");
        let notificationUrl = localStorage.getItem("notificationUrl");

        setAppName(_appName);
            //let _socket = io.connect("http://206.189.138.129:3001?app="+_appName,{
            let _socket = io.connect(notificationUrl+"?app="+_appName,{
            //   path: '/socket.io', transports: ['websocket'] 
            //transports: ['websocket'],
            //pingInterval: 1000 * 60 * 5,
            //pingTimeout: 1000 * 60 * 3
        });
        setSocket(_socket);
    }

    useEffect(()=>{
        getAppName()
    },[]);
    
    const value= {
        appName,
        socket,
    }

    return (
        <SocketContext.Provider value= {value}>
            {socket!==null && props.children}
        </SocketContext.Provider>
    )
}