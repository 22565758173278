
import React,{useEffect,useState} from 'react'
import { useParams } from 'react-router-dom';
import queueServices from '../queueServices';
import AppLayout from '../../../../layout/components/AppLayout'
import { NavLink } from 'react-router-dom'

const withQueueLayout = OriginalComponent =>(props)  =>{
    const {id} = useParams();
    const [queueConfig,setQueueConfig] = useState();
    const getQueueConfig = () =>{
        queueServices.getQueueConfig(id).then(res=>{
            setQueueConfig(res.data);
        })
    }

    useEffect(()=>{
        getQueueConfig();
    },[])
    
    
  return (

        <AppLayout>
            <div className='container-fluid'>
                {queueConfig &&
                <div className='row'>
                    <div className='col-md-3'>
                        <div className='list-group rounded-0'>
                            <span className="list-group-item bg-dark text-white text-center">{queueConfig.queueDetails.title}</span>
                            <NavLink className="list-group-item" aria-current="page" to={"/queue/config/"+id} >Queue Configuration</NavLink>
                            <NavLink className="list-group-item" aria-current="page" to={"/queue/business-hours/"+id} >Working Hours</NavLink>
                            <NavLink className="list-group-item" aria-current="page" to={"/queue/business-breaks/"+id} >Break Timing</NavLink>
                            <NavLink className="list-group-item" aria-current="page" to={"/queue/business-holiday/"+id} >Holidays</NavLink>
                            <NavLink className="list-group-item" aria-current="page" to={"/queue/services/"+id} >Services</NavLink>
                            <NavLink className="list-group-item" aria-current="page" to={"/queue/staff/"+id} >Staff</NavLink>
                        </div>
                        <div className='card rounded-0'>
                            <div className='card-body bg-dark '>
                            <table className='table text-white'>
                                    <tbody>
                                        <tr><th>OPD #</th><td>{queueConfig.queueDetails.id}</td></tr>
                                        <tr><th>Prefix</th><td>{queueConfig.queueDetails.prefix}</td></tr>
                                        <tr><th>Status</th><td>{queueConfig.queueDetails.status == 1 ? "Open" : "Close"}</td></tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-9'>
                        <OriginalComponent {...props} queueConfig={queueConfig} getQueueConfig={getQueueConfig} />
                    </div>
                </div>
                }
            </div>
        </AppLayout>
    )
}

export default withQueueLayout