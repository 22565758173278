import api from "../../../api/api";

const  serialize = (obj) =>{
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
}
const getStaffCollectionReport = (filters) =>{
    return new Promise((resolve, reject)=>{
        api.get("/accounts/collection-report?"+serialize(filters)).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getTransactionReport = (filters) =>{
    return new Promise((resolve, reject)=>{
        api.get("/accounts/transaction-report?"+serialize(filters)).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getPendencyReport = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/accounts/pendency-report").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getUserDDList = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/user/dd/list").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const accountsCollectionReportServices = {
    getStaffCollectionReport,
    getTransactionReport,
    getPendencyReport,
    getUserDDList
}
export default accountsCollectionReportServices;